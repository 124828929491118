<template>
  <div id="create-documents">
    <section>
      <h1><strong>Assunto</strong> (obrigatório)</h1>
      <MySelectFilter :name="'subject'" :value="documentSubjectSearch" v-model="documentSubjectSearch" @blur="verifyValueSubject">
        <li v-for="(subject, index) in documentSubjects" :key="index" @mousedown="selectedSubject = subject; documentSubjectSearch = subject.name;">{{ subject.name }}</li>
      </MySelectFilter>
    </section>
    <section class="exception" v-if="selectedSubject?.submission_end_day && (new Date().getDate() < selectedSubject?.submission_start_day || new Date().getDate() > selectedSubject?.submission_end_day)">
      <h1><strong>Exceção</strong> (obrigatório)</h1>
      <p>Esse documento está fora do prazo padrão de envio. Para enviar, marque como sendo uma exceção.</p>
      <label>
        <input type="radio" checked="true">
        <span></span>
        <p>Enviar como exceção</p>
      </label>
    </section>
    <section>
      <h1><strong>Tipo de documento?</strong> (obrigatório)</h1>
      <MySelect :name="'document_type'" :for="'document_type'" :currentValue="selectedDocumentType ? documentTypeTranslations[selectedDocumentType] : null">
        <li v-for="(type, index) in DocumentTypeEnum" :key="index" @mousedown="selectedDocumentType = type;">{{documentTypeTranslations[type]}}</li>
      </MySelect>
    </section>
    <section>
      <h1><strong>Setores</strong> (obrigatório)</h1>
      <h2>Qual setores irá ter acesso a esse documento?</h2>
      <MyOptions :currentValue="selectedOrganization?.name ?? 'Selecione'">
        <MyOption 
          v-for="(organization, index) in organizations" 
          :key="index"
          :name="'organizations'"
          :id="'organizations-' + index + 1"
          :value="organization"
          v-model="selectedOrganization"
          @change="(checked) => checked ? (selectedResponsible = undefined) : ''"
        >
          {{ organization.name }}
        </MyOption>
      </MyOptions>
    </section>
    <section>
      <h1><strong>Responsável</strong> (obrigatório)</h1>
      <h2>Quem irá ter acesso a esse documento?</h2>
      <MyOptions :currentValue="selectedResponsible?.name ?? 'Selecione'">
        <MyOption 
          v-for="(responsible, index) in usersToTransfer" 
          :key="index"
          :name="'responsible'"
          :id="'responsible-' + index + 1"
          :value="responsible"
          v-model="selectedResponsible"
          @change="(checked) => checked ? (selectedOrganization = selectedResponsible?.organization) : ''"
        >
          {{ responsible.name }}
        </MyOption>
      </MyOptions>
    </section>
    <section>
      <h1><strong>Titulo desse documento</strong> (obrigatório)</h1>
      <h2>Dê um titulo claro para esse documento.</h2>
      <input type="text" placeholder="Ex: OFICIO 25 - SOLICITAÇÃO DE FALTAS" name="title-document" id="title-document" v-model="title">
    </section>
    <section>
      <h1><strong>Número do ofício ou portaria</strong> (obrigatório)</h1>
      <h2>Adicione esse número para facilitar a organização no sistema.</h2>
      <input type="text" placeholder="Ex: 1011" name="title-document" id="title-document" v-model="physicalDocumentNumber">
    </section>
    <section>
      <h1><strong>Data limite</strong> (obrigatório)</h1>
      <h2>Prazo máximo para o documento ser apreciado.</h2>
      <label class="date-picker-label" for="date-limit-document" @click="isDatePickerModalOpen = true;">
        <input type="text" placeholder="Selecione uma data" name="date-limit-document" id="date-limit-document" :value="formattedLimitDate ? formattedLimitDate : ''" readonly>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M8 2V5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16 2V5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M3.5 9.08984H20.5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15.6937 13.7002H15.7027" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15.6937 16.7002H15.7027" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M11.9945 13.7002H12.0035" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M11.9945 16.7002H12.0035" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8.29529 13.7002H8.30427" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8.29529 16.7002H8.30427" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </label>
    </section>
    <section>
      <h1><strong>Conteúdo desse documento</strong> (obrigatório)</h1>
      <h2>Descreva os principais detalhes que irão ajudar aos interessados saber sobre o documento.</h2>
      <!-- <textarea class="text" v-model="content"></textarea> -->
      <TextEditor :value="content" :setValue="(value) => content = value" />
    </section>
    <section>
      <h1><strong>Palavras chaves</strong> (opcional)</h1>
      <h2>Separe por vírgula, exemplo: férias, pagamento.</h2>
      <MyTags v-model="keywords" :name="'keywords'" :placeholder="'Digite a palavra chave'"/>
    </section>
    <section>
      <h1><strong>Arquivo do documento</strong> (obrigatório)</h1>
      <h2>Adicione um arquivo a esse documento, podendo ser DOC ou PDF.</h2>
      <FileUploader :files="documentFiles" :pushFile="(file) => documentFiles.push(file)" />
    </section>
    <section class="spacing">
      <h1><strong>Citado(s) no(s) documento(s)</strong> (opcional)</h1>
      <h2>Escreva nome e sobrenome dos participantes citados no(s) anexo(s) e separe por vírgula.</h2>
      <MyTags v-model="mentions" :name="'mentions'" :placeholder="'Digite nome e sobrenome'"/>
    </section>
    <nav>
      <input v-if="!isCreatingDocument" type="button" value="Enviar" @click="createDocument" :disabled="!selectedSubject || !selectedDocumentType || !selectedOrganization || !selectedResponsible || !title || !physicalDocumentNumber || !limitDate || !content || !documentFiles.length">
      <SpinnerLoaderButton v-else :height="60" :maxWidth="464" />
      <input type="button" value="Cancelar" @click="$router.go(-1)">
    </nav>
    <MyModal :width="'341px !important'" :height="'517px !important'" :className="'date-limit'" :isOpen="isDatePickerModalOpen" :toggleModal="(v: boolean) => isDatePickerModalOpen = v" :zIndex="100">
      <div class="modal-date-title">
        <h1>Data limite</h1>
        <h2>Prazo máximo para ser aceito.</h2>
      </div>
      <MyDatePicker :min="{ day: new Date().getDate(), month: new Date().getMonth() + 1, year: new Date().getFullYear() }" :name="'limit'" :setValue="(date) => selectedLimitDate = date"/>
      <input type="button" value="Aplicar data" @click="limitDate = selectedLimitDate; isDatePickerModalOpen = false">
    </MyModal>
    <MyModal :width="'330px !important'" :height="'179px !important'" :isOpen="isCreateDocumentModalOpen" :class-name="'confirm-create-document'" :toggleModal="(v: boolean) => isCreateDocumentModalOpen = v" :zIndex="100">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
          <path d="M18.1787 40.0616V18.0482C18.1787 17.1816 18.4387 16.3366 18.9154 15.6216L24.8304 6.82491C25.762 5.41657 28.0804 4.41991 30.052 5.15657C32.1754 5.87157 33.5837 8.2549 33.1287 10.3782L32.002 17.4632C31.9154 18.1132 32.0887 18.6982 32.457 19.1532C32.8254 19.5649 33.367 19.8249 33.952 19.8249H42.857C44.5687 19.8249 46.042 20.5182 46.9087 21.7316C47.732 22.9016 47.8837 24.4182 47.342 25.9566L42.012 42.1849C41.3404 44.8716 38.4154 47.0599 35.512 47.0599H27.062C25.6104 47.0599 23.5737 46.5616 22.642 45.6299L19.8687 43.4849C18.807 42.6832 18.1787 41.4049 18.1787 40.0616Z" fill="#05CB95"/>
          <path d="M11.288 13.8232H9.05634C5.69801 13.8232 4.33301 15.1232 4.33301 18.3299V40.1266C4.33301 43.3332 5.69801 44.6332 9.05634 44.6332H11.288C14.6463 44.6332 16.0113 43.3332 16.0113 40.1266V18.3299C16.0113 15.1232 14.6463 13.8232 11.288 13.8232Z" fill="#05CB95"/>
        </svg>
        <h1>Tudo certo!</h1>
        <h2>Documento enviado com sucesso.</h2>
      </div>
    </MyModal>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import MyModal from '@/components/modal/MyModal.vue'
import MyDatePicker from '@/components/date/MyDatePicker.vue'
import MySelect from '@/components/select/MySelect.vue'
import MySelectFilter from '@/components/select/MySelectFilter.vue'
import MyTags from '@/components/tag/MyTags.vue'
import MyOptions from '@/components/options/MyOptions.vue'
import MyOption from '@/components/options/MyOption.vue'
import DocumentTypeEnum, { documentTypeTranslations } from '@/enums/DocumentTypeEnum'
import { OrganizationModel } from '@/models/OrganizationModel'
import { useAxios } from '@/api/axios'
import { UserModel } from '@/models/UserModel'
import TextEditor from '@/components/input/TextEditor.vue'
import FileUploader from '@/components/input/FileUploader.vue'
import DocumentSubjectModel from '@/models/DocumentSubjectModel'
import SpinnerLoaderButton from '@/components/loader/SpinnerLoaderButton.vue'
import DocumentTagTypeEnum from '@/enums/DocumentTags/DocumentTagTypeEnum'
import { user as authenticatedUser } from '@/global/states/GlobalState'

const axios = useAxios()
const organizations = ref<OrganizationModel[]>()
const users = ref<UserModel[]>([])
const usersToTransfer = computed(() => users.value?.filter((user) => ((selectedOrganization.value ? user.organization_id === selectedOrganization.value?.id : true) && (user.id !== authenticatedUser.value.id))))
const documentSubjects = computed(() => {
    return documentSubjectsInitial.value?.filter(
      subject =>
        subject.name.toLowerCase().includes(documentSubjectSearch.value.toLowerCase())
    )
})
const documentSubjectsInitial = ref<DocumentSubjectModel[]>()
const isCreatingDocument = ref(false)
const documentSubjectSearch = ref('')

/* FORM */
const selectedSubject = ref<DocumentSubjectModel>()
const selectedDocumentType = ref<DocumentTypeEnum>()
const selectedOrganization = ref<OrganizationModel>()
const selectedResponsible = ref<UserModel>()
const title = ref<string>('')
const physicalDocumentNumber = ref<string>('')
const content = ref<string>('')
const documentFiles = ref<File[]>([])
const selectedLimitDate = ref('')
const limitDate = ref('')
const formattedLimitDate = computed(() => limitDate.value ? formatDate(limitDate.value) : undefined)
const mentions = ref<string[]>([])
const keywords = ref<string[]>([])
/* FORM */

const isDatePickerModalOpen = ref<boolean>(false)
const isCreateDocumentModalOpen = ref<boolean>(false)

const verifyValueSubject = (value: string) => {
  if (documentSubjectsInitial.value) {
    let onClear = false
    for (const [index, options] of documentSubjectsInitial.value.entries()) {
      if (options.name == value) {
        onClear = true
      }
    }
    if (!onClear) {
      documentSubjectSearch.value = ''
      selectedSubject.value = undefined
    }
  }
}

const formatDate = (date: string) => {
  const [year, mouth, day] = date.split('-')
  return `${day}/${mouth}/${year}`
}

function createDocument (): void {
  isCreatingDocument.value = true

  const payload = new FormData()
  payload.append('document_subject_id', String(selectedSubject.value!.id))
  payload.append('type', selectedDocumentType.value as string)
  payload.append('organization_ids[]', String(selectedOrganization.value!.id))
  payload.append('user_ids[]', String(selectedResponsible.value!.id))
  payload.append('title', title.value)
  payload.append('physical_document_number', physicalDocumentNumber.value)
  payload.append('limit_date', limitDate.value)
  payload.append('content', content.value)
  for (const key in documentFiles.value) {
    payload.append('files[]', documentFiles.value[key])
  }
  let tagsIndex = 0
  const appendTags = (items: string[], type: DocumentTagTypeEnum) => {
    items.forEach((item) => {
      payload.append(`tags[${tagsIndex}][title]`, item)
      payload.append(`tags[${tagsIndex}][type]`, type)
      tagsIndex++
    })
  }

  appendTags(mentions.value, DocumentTagTypeEnum.MENTION)
  appendTags(keywords.value, DocumentTagTypeEnum.KEYWORD)

  axios.post('/api/documents', payload)
    .then(() => {
      isCreateDocumentModalOpen.value = true
      resetForm()
    })
    .finally(() => isCreatingDocument.value = false)
}

function resetForm (): void {
  documentSubjectSearch.value = ''
  selectedSubject.value = undefined
  selectedDocumentType.value = undefined
  selectedOrganization.value = undefined
  selectedResponsible.value = undefined
  title.value = ''
  physicalDocumentNumber.value = ''
  limitDate.value = ''
  content.value = ''
  documentFiles.value = []
  mentions.value = []
  keywords.value = []
  selectedLimitDate.value = ''
}

function getOrganizations () {
  axios.get('/api/organizations')
    .then((res) => {
      organizations.value = res.data
    })
}

function getUsers () {
  axios.get('/api/users')
    .then((res) => {
      users.value = res.data
    })
}

function getDocumentSubjects () {
  axios.get('/api/document-subjects')
    .then((res) => {
      documentSubjectsInitial.value = res.data
    })
}

onMounted(() => {
  getOrganizations()
  getUsers()
  getDocumentSubjects()
})
</script>

<style lang="scss">
  #create-documents{
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 28px;
    padding: 25px 0 0 0;
    section{
      width: 100%;
      display: flex;
      flex-direction: column;
      h1{
        color: #090909;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0 0 13px 0;
        text-align: start;
        strong{
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
      h2{
        color: #6F6F6F;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: start;
        margin: -13px 0 13px 0;
      }
      input[type="text"]{
        width: 100%;
        height: 55px;
        border-radius: 14px;
        border: 1px solid #D3D3D3;
        color: $fluxoo-primary-color;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 15px 20px;
        box-sizing: border-box;
        transition: border-color .2s, color .2s, background .2s;
        &:focus{
          outline: none;
        }
        &::placeholder{
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          transition: color .2s;
        }
      }
      label.check{
        input{
          display: none;
          &:checked ~ span{
            background: $fluxoo-primary-color;
            border-color: $fluxoo-primary-color;
          }
        }
        span{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          min-width: 24px;
          height: 24px;
          min-height: 24px;
          border-radius: 8px;
          border: 1px solid #6F6F6F;
          transition: border-color .2s, background .2s;
          cursor: pointer;
        }
      }
      .date-picker-label{
        position: relative;
        display: flex;
        align-items: center;
        input{
          cursor: pointer;
          color: $fluxoo-primary-color;
        }
        svg{
          width: 24px;
          min-width: 24px;
          height: 24px;
          min-height: 24px;
          stroke: #090909;
          position: absolute;
          right: 22px;
        }
      }
    }
    .exception{
      background: #EDF5F3;
      padding: 20px;
      border-radius: 18px;
      box-sizing: border-box;
      h1{
        margin: 0
      }
      p{
        color: var(--cinza-forte, #6F6F6F);
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        margin: 0;
      }
      label{
        display: flex;
        align-items: center;
        column-gap: 8px;
        margin-top:.94rem;
        cursor: pointer;
        input{
          display: none;
          &:checked{
            & ~ span{
              border-color: $fluxoo-primary-color;
              outline: 1px solid $fluxoo-primary-color;
              &::before{
                transform: scale(1.0);
                opacity: 1;
              }
            }
            & ~ p{
              color: $fluxoo-primary-color;
            }
          }
        }
        span{
          width: 24px;
          min-width: 24px;
          height: 24px;
          min-height: 24px;
          border-radius: 50%;
          border: 1px solid #D5D5D5;
          position: relative;
          transition: border-color .2s;
          &::before{
            content: '';
            width: 12px;
            min-width: 12px;
            height: 12px;
            min-height: 12px;
            border-radius: 50%;
            background: $fluxoo-primary-color;
            position: absolute;
            top: 6px;
            left: 6px;
            transform: scale(0.0);
            opacity: 0;
            transition: transform .2s, opacity .1s;
          }
        }
        p{
          color: #949494;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 17px;
          margin: 0;
          transition: color .2s;
        }
      }
    }
    nav{
      width: 100%;
      max-width: 1200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 32px;
      border-radius: 16px;
      background: #FFF;
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      position: sticky;
      bottom: 5px;
      column-gap: 20px;
      input{
        border: none;
        cursor: pointer;
        &:first-child{
          width: 100%;
          max-width: 464px;
          height: 60px;
          border-radius: 30px;
          background: $fluxoo-primary-color;
          color: #FFF;
          text-align: center;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          transition: background .2s;
          &:hover{
            background: $fluxoo-primary-color-hover;
          }
        }
        &:last-child{
          color: #737373;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          background: unset;
          transition: color .2s;
          &:hover{
            color: $fluxoo-primary-color;
          }
        }
      }
    }
    .modal{
      &.date-limit{
        .modal__content{
          padding: 15px 28px;
          box-sizing: border-box;
          overflow-y: auto;
          &::-webkit-scrollbar{
            width: 0;
          }
          button{
            display: flex;
            top: 26px;
            right: 28px;
          }
          .modal-date-title{
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: start;
            margin: 0 0 25px 0;
            h1{
              color: #090909;
              font-family: 'Nunito', sans-serif;
              font-size: 32px;
              font-style: normal;
              font-weight: 800;
              line-height: normal;
              text-align: start;
              margin: 0;
            }
            h2{
              color: #6F6F6F;
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 2px;
              text-align: start;
              margin: 0;
            }
          }
          input[type='button']{
            width: 100%;
            min-height: 55px;
            border-radius: 30px;
            border: none;
            background: $fluxoo-primary-color;
            color: #FFF;
            text-align: center;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 8px 0 0 0;
            transition: background .2s;
            cursor: pointer;
            &:disabled{
              background: #F1F1F1 !important;
              color: #6F6F6F !important;
              cursor: not-allowed !important;
            }
            &:hover{
              background: $fluxoo-primary-color-hover;
            }
          }
        }
      }
      &.confirm-create-document{
        div{
          div{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 26px 15px 0;
            h1{
              color: #090909;
              font-family: 'Nunito', sans-serif;
              font-size: 28px;
              font-style: normal;
              font-weight: 900;
              line-height: normal;
              margin: 2px 0 7px 0;
            }
            h2{
              color: #6F6F6F;
              text-align: center;
              font-family: 'Inter', sans-serif;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin: 0;
            }
          }
        }
        button{
          display: flex;
          top: 28px;
          right: 28px;
        }
      }
    }
  }

  @media(max-width: 768px){
    #create-documents{
      nav{
        padding: 15px;
      }
    }
  }

  @media(max-width: 425px){
    #create-documents{
      padding: 25px 0 120px 0;
      nav{
        animation: enterToBottom ease 0.9s;
        width: 100vw;
        position: fixed;
        left: 0;
        bottom: 0;
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
        padding: 15px;
        box-sizing: border-box;
        z-index: 4;
      }
      .modal {
        padding: unset;
        &.date-limit{
          padding: 0 10px;
          .modal__content{
            border-radius: 28px;
            padding: 15px;
            .date-picker{
              .select-day{
                .date{
                  gap: 0;
                }
              }
            }
          }
        }
        &.confirm-create-document{
          padding: 0 15px;
          .modal__content{
            border-radius: 28px;
            bottom: unset;
            left: unset;
            right: unset;
            top: 0;
            opacity: 0;
            transition: all 0.2s ease-out;
            &.closed {
                opacity: 0;
                transform: translateY(-110%) !important;
            }
            &.open {
                opacity: 1;
                transform: translateY(0) !important;
            }
          }
        }
      }
    }
  }
</style>
