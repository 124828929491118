enum DocumentStatusEnum {
    PENDING = 'pending',
    UNDER_REVIEW = 'under_review',
    EXTENDED = 'extended',
    OVERDUE = 'overdue',
    COMPLETED = 'completed'
}

export const documentStatusTranslations: Record<string, string> = {
  sent: 'Enviados',
  [DocumentStatusEnum.OVERDUE]: 'Em atraso',
  [DocumentStatusEnum.COMPLETED]: 'Finalizado',
  [DocumentStatusEnum.EXTENDED]: 'Prorrogado',
  [DocumentStatusEnum.PENDING]: 'Pendente',
  [DocumentStatusEnum.UNDER_REVIEW]: 'Tramitando'
}

export const documentStatusDescriptions: Record<string, string> = {
  sent: 'Todos que foram enviados.',
  [DocumentStatusEnum.OVERDUE]: 'Todos que não foram vistos até a data limite.',
  [DocumentStatusEnum.COMPLETED]: 'Todos que foram vistos e concluídos.',
  [DocumentStatusEnum.EXTENDED]: 'Todos que tiveram datas modificadas.',
  [DocumentStatusEnum.PENDING]: 'Todos que não foram vistos.',
  [DocumentStatusEnum.UNDER_REVIEW]: 'Enviados, mas nem todos foram vistos.'
}

export default DocumentStatusEnum
