import { createApp, App } from 'vue';
import FxNotification from '@/components/notification/FxNotification.vue';

let toastInstance: any;

export function useFxToast() {
  if (!toastInstance) {
    const toastComponent = createApp(FxNotification);
    toastInstance = toastComponent.mount(document.createElement('div'));
    document.body.appendChild(toastInstance.$el);

    return {
      show(title: string, body: string, href: string) {
        (toastInstance as any).show(title, body, href);
      },
    };
  } else {
    return {
      show(title: string, body: string, href: string) {
        (toastInstance as any).show(title, body, href);
      },
    };
  }
}