<template>
<div id="edit-document-subject" v-if="payload.id">
  <section>
    <h1><strong>Crie um assunto de documento</strong> (obrigatório)</h1>
    <input type="text" placeholder="Ex: Secretaria de Segurança e Trânsito" name="name" id="namet" v-model="payload.name">
  </section>
  <section>
    <h1><strong>Dia de envio</strong> (opcional)</h1>
    <h2>Dia limite para envio de documentos sobre esse assunto</h2>
    <div class="slider">
      <MyDoubleRange :updateValues="updateValues" :startValue="payload.submission_start_day" :endValue="payload.submission_end_day"/>
    </div>
  </section>
  <section>
    <h1><strong>Setores isentos</strong> (opcional)</h1>
    <h2>Quais setores serão isentos de exceção(prazo de envio)?</h2>
    <MyOptions :currentValue="payload.exempt_submission_date_organizations?.length ? payload.exempt_submission_date_organizations.map(org => org.name).join(', ') : 'Selecione'">
      <div class="item" v-for="(org, index) in organizations" :key="index">
        <input type="checkbox" :id="'organization-' + index + 1" name="organizations" :checked="payload.exempt_submission_date_organizations?.find((o) => o.id == org.id) ? true : false"
        @change="($event.target as HTMLInputElement).checked ? payload.exempt_submission_date_organizations!.push(org) : payload.exempt_submission_date_organizations = payload.exempt_submission_date_organizations?.filter((o) => o.id != org.id)">
        <label :for="'organization-' + index + 1">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none">
              <g clip-path="url(#clip0_388_3128)">
                <path d="M12.3047 2.42188C12.3047 2.63021 12.2318 2.80729 12.0859 2.95312L6.42969 8.60938L5.36719 9.67188C5.22135 9.81771 5.04427 9.89062 4.83594 9.89062C4.6276 9.89062 4.45052 9.81771 4.30469 9.67188L3.24219 8.60938L0.414062 5.78125C0.268229 5.63542 0.195312 5.45833 0.195312 5.25C0.195312 5.04167 0.268229 4.86458 0.414062 4.71875L1.47656 3.65625C1.6224 3.51042 1.79948 3.4375 2.00781 3.4375C2.21615 3.4375 2.39323 3.51042 2.53906 3.65625L4.83594 5.96094L9.96094 0.828125C10.1068 0.682292 10.2839 0.609375 10.4922 0.609375C10.7005 0.609375 10.8776 0.682292 11.0234 0.828125L12.0859 1.89062C12.2318 2.03646 12.3047 2.21354 12.3047 2.42188Z"/>
              </g>
              <defs>
                <clipPath id="clip0_388_3128">
                  <rect width="12.5" height="10" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </span>
          <p>{{ org.name }}</p>
        </label>
      </div>
    </MyOptions>
  </section>
  <section class="spacing">
    <h1><strong>Status</strong></h1>
    <label for="stats" class="switch">
      <input type="checkbox" name="stats" id="stats" v-model="payload.status">
      <span></span>
    </label>
  </section>
  <nav>
    <input v-if="!isUpdatingDocumentSubject" type="button" value="Salvar" @click="updateDocumentSubject" :disabled="!payload.name">
    <SpinnerLoaderButton v-else :height="60" :maxWidth="464"/>
    <input type="button" value="Cancelar" @click="$router.push({ name: 'admin.home' })">
  </nav>
</div>
<SpinnerLoader v-else />
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { useAxios } from '@/api/axios'
import DocumentSubjectModel from '@/models/DocumentSubjectModel'
import { toast } from 'vue3-toastify'
import { AxiosResponse } from 'axios'
import DocumentSubjectStatusEnum from '@/enums/DocumentSubjectStatusEnum'
import SpinnerLoaderButton from '@/components/loader/SpinnerLoaderButton.vue'
import SpinnerLoader from '@/components/loader/SpinnerLoader.vue'
import { OrganizationModel } from '@/models/OrganizationModel'
import MyOptions from '@/components/options/MyOptions.vue'
import VueSlider from 'vue3-slider'
import MyDoubleRange from '@/components/range/MyDoubleRange.vue'

const props = defineProps<{ id: number }>()
const axios = useAxios()
const payload = ref<Partial<DocumentSubjectModel>>({})
const organizations = ref<OrganizationModel[]>()
const isUpdatingDocumentSubject = ref(false)

const updateValues = (value1: number, value2: number) => {
  if (value1 != 0 && value2 != 0) {
    payload.value.submission_start_day = value1
    payload.value.submission_start_end = value2
  } else if (value1 == 0 && value2 != 0) {
    payload.value.submission_start_day = 1
    payload.value.submission_start_end = value2
  } else if (value1 == 0 && value2 == 0) {
    payload.value.submission_start_day = undefined
    payload.value.submission_start_end = undefined
  }
}

function updateDocumentSubject () {
  isUpdatingDocumentSubject.value = true

  const payloadData = {
    document_subject_ids: [payload.value.id],
    name: payload.value.name,
    submission_start_day: payload.value.submission_start_day,
    submission_end_day: payload.value.submission_start_end,
    status: payload.value.status ? DocumentSubjectStatusEnum.ACTIVE : DocumentSubjectStatusEnum.INACTIVE,
    submission_date_exempt_organization_ids: payload.value.exempt_submission_date_organizations!.map((org) => org.id)
  }

  axios.put('/api/document-subjects', payloadData)
    .then(() => {
      toast.success('Assunto editado com sucesso')
    })
    .finally(() => isUpdatingDocumentSubject.value = false)
}

function getDocumentSubject () {
  axios.get(`/api/document-subjects/${props.id}`)
    .then((res: AxiosResponse<DocumentSubjectModel>) => {
      payload.value = { ...res.data, status: res.data.status == DocumentSubjectStatusEnum.ACTIVE }
    })

  console.log(payload.value)
}

function getOrganizations () {
  axios.get('/api/organizations')
    .then((res) => {
      organizations.value = res.data
    })
}

watch(() => payload.value.submission_end_day, (newValue) => {
  if (payload.value.submission_end_day <= payload.value.submission_start_day) {
    payload.value.submission_start_day = payload.value.submission_end_day - 1
  }
})

onMounted(() => {
  getDocumentSubject()
  getOrganizations()
})
</script>

<style lang="scss">
#edit-document-subject{
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 28px;
  padding: 25px 0 0 0;
  section{
    width: 100%;
    display: flex;
    flex-direction: column;
    h1{
      color: #090909;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0 0 13px 0;
      text-align: start;
      strong{
        color: #090909;
        font-family: 'Inter', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    h2{
      color: #6F6F6F;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: start;
      margin: -13px 0 13px 0;
    }
    input[type="text"]{
      width: 100%;
      height: 55px;
      border-radius: 14px;
      border: 1px solid #D3D3D3;
      color: $fluxoo-primary-color;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 15px 20px;
      box-sizing: border-box;
      transition: border-color .2s, color .2s, background .2s;
      &:focus{
        outline: none;
      }
      &::placeholder{
        color: #090909;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        transition: color .2s;
      }
    }
    label.switch{
      width: 105px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      cursor: pointer;
      input{
        display: none;
        &:checked{
          & ~ span{
            background: $fluxoo-primary-color-hover;
            &::before{
              background: $fluxoo-primary-color;
              left: calc(100% - 25px);
            }
            &::after{
              content: 'Ativo';
              color: $fluxoo-primary-color;
            }
          }
        }
      }
      span{
        width: 43px;
        height: 11px;
        background: #949494;
        border-radius: 12px;
        position: relative;
        transition: background .2s;
        &::before{
          content: '';
          width: 25px;
          height: 25px;
          background: #D5D5D5;
          border-radius: 50%;
          position: absolute;
          top: -7px;
          left: 0;
          transition: background .2s, left .2s;
        }
        &::after{
          content: 'Inativo';
          position: absolute;
          left: calc(100% + 10px);
          bottom: -4px;
          color: #949494;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          transition: color .2s;
        }
      }
    }
  }
  nav{
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 32px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    position: sticky;
    bottom: 5px;
    column-gap: 20px;
    margin: auto 0 0 0;
    input{
      border: none;
      cursor: pointer;
      &:first-child{
        width: 100%;
        max-width: 464px;
        height: 60px;
        border-radius: 30px;
        background: $fluxoo-primary-color;
        color: #FFF;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        transition: background .2s;
        &:hover{
          background: $fluxoo-primary-color-hover;
        }
      }
      &:last-child{
        color: #737373;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        background: unset;
        transition: color .2s;
        &:hover{
          color: $fluxoo-primary-color;
        }
      }
    }
  }
}

@media(max-width: 425px){
  #edit-document-subject{
    padding: 25px 0 120px 0;
    nav{
      animation: enterToBottom ease 0.9s;
      width: 100vw;
      position: fixed;
      left: 0;
      bottom: 0;
      border-radius: 20px 20px 0px 0px;
      background: #FFF;
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
      padding: 15px;
      box-sizing: border-box;
      z-index: 4;
    }
  }
}
</style>
