<template>
  <div class="date-picker">
    <div class="select-date">
      <MySelect :name="'year-date-' + name + '-year'" :for="'year-date-' + name + '-year'" :currentValue="selectedYear.toString()">
        <li v-for="(year, index) in yearsDatePicker" :key="index" @mousedown="selectedYear = year.toString(); daysInMonth(); selectedDay = '15';">{{ year }}</li>
      </MySelect>
      <MySelect :name="'mouth-date-' + name + '-mouth'" :for="'year-date-' + name + '-mouth'" :currentValue="mouthsDatePicker[selectedMonth]">
        <li v-for="(month, index) in mouthsDatePicker" :key="index" @mousedown="selectedMonth = index; daysInMonth(); selectedDay = '15';">{{ month }}</li>
      </MySelect>
    </div>
    <div class="select-day">
        <div class="names date">
          <p>Dom</p>
          <p>Seg</p>
          <p>Ter</p>
          <p>Qua</p>
          <p>Qui</p>
          <p>Sex</p>
          <p>Sab</p>
        </div>
        <div class="days date">
          <label class="day" :class="{undefined: day == -1, disabled: isDayBeforeCurrent(day)}" v-for="(day, index) in daysInMonthList" :key="index">
            <input :disabled="isDayBeforeCurrent(day)" type="radio" v-if="day !== -1" :value="day" :id="'day-' + name + index" :name="'day-limit-' + name" v-model="selectedDay">
            <span>{{day == -1 ? '•' : day}}</span>
          </label>
        </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, watch, onMounted } from 'vue'
import MySelect from '@/components/select/MySelect.vue'

const props = defineProps<{
    name: string,
    setValue:(value: string) => void,
    format?: string,
    min: { day: number, month: number, year: number }
  }>()

const selectedYear = ref<number | string>(new Date(props.min.year, props.min.month - 1, props.min.day).getFullYear())
const selectedMonth = ref<number>(new Date(props.min.year, props.min.month - 1, props.min.day).getMonth())
const selectedDay = ref<string>(formatSingleDigit(new Date(props.min.year, props.min.month - 1, props.min.day).getDate()))

const mouthsDatePicker = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

const yearsDatePicker: number[] = []
for (let i = new Date(props.min.year, props.min.month - 1, props.min.day).getFullYear(); i >= 2000; i--) {
  yearsDatePicker.push(i)
}

let daysInMonthList: any[] = []

function getFirstDayOfWeekAndDaysInMonth (year: number, month: number) {
  const date = new Date(year, month, 1)
  const firstDayOfWeek = date.getDay()

  const nextMonthDate = new Date(year, month + 1, 0)
  const daysInMonth = nextMonthDate.getDate()

  return {
    firstDayOfWeek: firstDayOfWeek,
    daysInMonth: daysInMonth
  }
}

function daysInMonth () {
  const firstDayOfWeek = getFirstDayOfWeekAndDaysInMonth(selectedYear.value as number, selectedMonth.value).firstDayOfWeek
  const days = getFirstDayOfWeekAndDaysInMonth(selectedYear.value as number, selectedMonth.value).daysInMonth

  daysInMonthList = []

  switch (firstDayOfWeek) {
    case 1:
      for (let i = 0; i < 1; i++) {
        daysInMonthList.push(-1)
      }
      break
    case 2:
      for (let i = 0; i < 2; i++) {
        daysInMonthList.push(-1)
      }
      break
    case 3:
      for (let i = 0; i < 3; i++) {
        daysInMonthList.push(-1)
      }
      break
    case 4:
      for (let i = 0; i < 4; i++) {
        daysInMonthList.push(-1)
      }
      break
    case 5:
      for (let i = 0; i < 5; i++) {
        daysInMonthList.push(-1)
      }
      break
    case 6:
      for (let i = 0; i < 6; i++) {
        daysInMonthList.push(-1)
      }
      break
    default:
      break
  }

  for (let i = 0; i < days; i++) {
    daysInMonthList.push(formatSingleDigit(i + 1).toString())
  }
}

function isDayBeforeCurrent (day: any) {
  const currentDate = new Date(props.min.year, props.min.month - 1, props.min.day)
  const selectedDate = new Date(selectedYear.value as number, selectedMonth.value, day)

  if (
    selectedDate.getFullYear() < currentDate.getFullYear() ||
      (selectedDate.getFullYear() === currentDate.getFullYear() && selectedDate.getMonth() < currentDate.getMonth()) ||
      (selectedDate.getFullYear() === currentDate.getFullYear() && selectedDate.getMonth() === currentDate.getMonth() && day < currentDate.getDate())
  ) {
    return true
  } else {
    return false
  }
}

function formatSingleDigit (number: number) {
  if (number.toString().length === 1) {
    return '0' + number
  } else {
    return number.toString()
  }
}

function parseSingleDigit (numberStr: string) {
  if (numberStr.length === 1) {
    return parseInt(numberStr)
  } else if (numberStr[0] === '0') {
    return parseInt(numberStr[1])
  } else {
    return parseInt(numberStr)
  }
}

function formatDate (format: string) {
  const year = selectedYear.value
  const month = (selectedMonth.value + 1).toString().padStart(2, '0')
  const day = selectedDay.value.toString().padStart(2, '0')

  return format.replace('yyyy', year.toString()).replace('mm', month).replace('dd', day)
}

daysInMonth()

onMounted(() => {
  const formattedDate = props.format ? formatDate(props.format) : `${selectedYear.value}-${(selectedMonth.value + 1).toString().padStart(2, '0')}-${selectedDay.value}`
  props.setValue(formattedDate)
})

watch([selectedYear, selectedMonth, selectedDay], () => {
  const currentDate = new Date(props.min.year, props.min.month - 1, props.min.day)
  const selectedDate = new Date(selectedYear.value as number, selectedMonth.value, parseSingleDigit(selectedDay.value))

  if (
    selectedDate.getFullYear() < currentDate.getFullYear() ||
      (selectedDate.getFullYear() === currentDate.getFullYear() && selectedDate.getMonth() < currentDate.getMonth()) ||
      (selectedDate.getFullYear() === currentDate.getFullYear() && selectedDate.getMonth() === currentDate.getMonth() && selectedDate.getDate() < currentDate.getDate())
  ) {
    const formattedDate = props.format ? formatDate(props.format) : `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`
    props.setValue(formattedDate)
  } else {
    const formattedDate = props.format ? formatDate(props.format) : `${selectedYear.value}-${(selectedMonth.value + 1).toString().padStart(2, '0')}-${selectedDay.value.toString().padStart(2, '0')}`
    props.setValue(formattedDate)
  }
})
</script>

<style lang="scss">

  .date-picker{
    display: flex;
    flex-direction: column;
    margin: 0 0 auto 0;
    .select-date{
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: 8px;
      margin: 0 0 25px 0;
      label{
        display: flex;
        position: relative;
        input{
          width: 100%;
          height: 55px;
          border-radius: 30px;
          background: #E0ECE9;
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          box-sizing: border-box;
          border: none;
          padding: 0 20px 0 20px;
          cursor: pointer;
          &:focus{
            outline: unset;
          }
        }
        &.open{
          &::before{
            top: 24px;
            border-color: $fluxoo-primary-color;
            transform: rotate(405deg);
          }
          input{
            border: 2px solid $fluxoo-primary-color;
            color: $fluxoo-primary-color;
            padding: 14px 19px;
            &::placeholder{
              color: $fluxoo-primary-color;
            }
          }
          div{
            display: flex;
          }
        }
        &:first-child{
          max-width: 120px;
        }
        &::before{
          content: "";
          width: 10px;
          height: 10px;
          border-left: 2px solid #292D32;
          border-top: 2px solid #292D32;
          border-radius: 2px;
          position: absolute;
          top: 19px;
          right: 20px;
          transform: rotate(225deg);
          transition: border-color .2s;
          cursor: pointer;
        }
      }
      .mySelect{
        width: 100%;
        column-gap: unset;
        input{
          width: 100%;
          background: $fluxoo-menu-primary-color-hover;
          border: unset !important;
          padding: 15px 20px !important;
        }
        &:hover{
          input{
            color: $fluxoo-primary-color;
          }
          &:before{
            border-color: $fluxoo-primary-color;
          }
        }
        &.open{
          &:before{
            border-color: #FFF;
          }
          input{
            border: unset !important;
            color: #FFF !important;
            background: $fluxoo-primary-color !important;
            padding: 15px 20px !important;
          }
          ul{
            display: flex;
          }
        }
      }
    }
    .select-day{
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      .date{
        display: grid;
        grid-template-columns: repeat(7, 38px);
        gap: 3px;
        margin: 0;
        justify-content: center;
      }
      .names{
        p{
          color: #6F6F6F;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin: 0;
          text-align: center;
          justify-content: center;
        }
      }
      .days{
        .day{
          display: flex;
          span{
            width: 37px;
            min-width: 37px;
            height: 37px;
            min-height: 37px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #090909;
            font-family: 'Inter', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            border: 1px solid transparent;
            transition: border-color .2s, background .2s, color .2s;
            cursor: pointer;
            &:hover{
              border-color: $fluxoo-primary-color;
              color: $fluxoo-primary-color;
            }
          }
          input{
            display: none;
            &:checked{
              & ~ span{
                border-color: $fluxoo-primary-color;
                background: $fluxoo-primary-color;
                color: #FFF;
                cursor: default;
              }
            }
          }
          &.undefined{
            span{
              color: #6F6F6F;
              border-color: transparent !important;
              cursor: default;
            }
          }
          &.disabled{
            span{
              color: #6F6F6F !important;
              border-color: transparent !important;
              background: transparent !important;
              cursor: default !important;
            }
          }
          &::before{
            display: none;
          }
        }
      }
    }
  }
</style>
