import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { toast } from 'vue3-toastify'
import { ref } from 'vue'
import router from '@/router'

const hasCsrfToken = ref(false)

export const axiosClient = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  },
  withXSRFToken: true
})

axiosClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401 && window.location.pathname.includes('admin')) {
      router.push({ name: 'login' })
    }
    return Promise.reject(error)
  }
)

export const csrf = () => axiosClient.get('/sanctum/csrf-cookie', { withCredentials: true })

export interface ApiErrorResponse {
    errors: {
        [key: string]: string[]; // As chaves são os nomes dos campos, e os valores são arrays de mensagens de erro
    };
    message: string;
}

export const isPerformingRequest = ref<boolean>(false)

export function useAxios () {
  const get = async (url: string, config: AxiosRequestConfig = {}) => {
    isPerformingRequest.value = true
    let csrfToken: any = ''

    if (!hasCsrfToken.value) {
      csrfToken = await csrf()
      hasCsrfToken.value = true
    }

    try {
      return await axiosClient.get(url, {
        headers: {
          'X-Xsrf-Token': csrfToken.data
        },
        ...config
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError<ApiErrorResponse>
        for (const input in e.response?.data.errors) {
          toast.error(e.response?.data.errors[input][0]!)
        }
        console.error('Erro na requisição:', error)
      } else {
        console.error('Erro na requisição:', error)
      }
      throw error // Lança o erro novamente para que o componente que chama useAxios possa lidar com ele.
    } finally {
      isPerformingRequest.value = false
    }
  }

  const post = async (url: string, body: any = {}) => {
    isPerformingRequest.value = true
    const tokenCrf = await csrf()
    try {
      return await axiosClient.post(url, body, {
        headers: {
          'X-Xsrf-Token': tokenCrf.data,
          'Content-Type': 'multipart/form-data'
        }
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError<ApiErrorResponse>
        for (const input in e.response?.data.errors) {
          toast.error(e.response?.data.errors[input][0]!)
        }
        console.error('Erro na requisição:', error)
      } else {
        console.error('Erro na requisição:', error)
      }
      throw error // Lança o erro novamente para que o componente que chama useAxios possa lidar com ele.
    } finally {
      isPerformingRequest.value = false
    }
  }

  const patch = async (url: string, body: FormData | Record<string, any>) => {
    if (body instanceof FormData) {
      body.append('_method', 'PATCH')
    } else {
      body._method = 'PATCH'
    }

    return post(url, body)
  }

  const put = async (url: string, body: FormData | Record<string, any>) => {
    if (body instanceof FormData) {
      body.append('_method', 'PUT')
    } else {
      body._method = 'PUT'
    }

    return post(url, body)
  }

  return {
    get,
    post,
    patch,
    put,
    delete: async (url: string, body: FormData | Record<string, any>) => {
      if (body instanceof FormData) {
        body.append('_method', 'DELETE')
      } else {
        body._method = 'DELETE'
      }

      return post(url, body)
    }
  }
}
