<template>
    <div id="create-organizations">
      <section>
        <h1><strong>Crie um setor</strong> (obrigatório)</h1>
        <input type="text" placeholder="Ex: Secretaria de Segurança e Trânsito" name="name" id="namet" v-model="payload.name">
      </section>
      <section>
        <h1><strong>Iniciais do setor</strong> (obrigatório)</h1>
        <input type="text" placeholder="Ex: S.M.S.T" name="acronym" id="acronym" v-model="payload.acronym">
      </section>
      <section class="spacing">
        <h1><strong>Status</strong> (obrigatório)</h1>
        <label for="stats">
          <input type="checkbox" name="stats" id="stats" v-model="payload.status">
          <span></span>
        </label>
      </section>
      <nav>
        <input v-if="!isCreatingOrganization" type="button" value="Criar" @click="createOrganization" :disabled="!payload.name || !payload.acronym">
        <SpinnerLoaderButton v-else :height="60" :maxWidth="464"/>
        <input type="button" value="Cancelar" @click="$router.go(-1)">
      </nav>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useAxios } from '@/api/axios'
import { OrganizationModel } from '@/models/OrganizationModel'
import { toast } from 'vue3-toastify'
import SpinnerLoaderButton from '@/components/loader/SpinnerLoaderButton.vue'

const axios = useAxios()
const payload = ref<Partial<OrganizationModel>>({})
const isCreatingOrganization = ref(false)

function createOrganization () {
  isCreatingOrganization.value = true

  axios.post('/api/organizations', { ...payload.value, status: payload.value.status ? 'active' : 'inactive' })
    .then(() => {
      toast.success('Setor criado com sucesso')
      payload.value = {}
    })
    .finally(() => isCreatingOrganization.value = false)
}
</script>

<style lang="scss">
  #create-organizations{
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 28px;
    padding: 25px 0 0 0;
    section{
      width: 100%;
      display: flex;
      flex-direction: column;
      h1{
        color: #090909;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0 0 13px 0;
        text-align: start;
        strong{
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
      h2{
        color: #6F6F6F;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: start;
        margin: -13px 0 13px 0;
      }
      input[type="text"]{
        width: 100%;
        height: 55px;
        border-radius: 14px;
        border: 1px solid #D3D3D3;
        color: $fluxoo-primary-color;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 15px 20px;
        box-sizing: border-box;
        transition: border-color .2s, color .2s, background .2s;
        &:focus{
          outline: none;
        }
        &::placeholder{
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          transition: color .2s;
        }
      }
      label{
        width: 105px;
        display: flex;
        align-items: center;
        column-gap: 10px;
        cursor: pointer;
        input{
          display: none;
          &:checked{
            & ~ span{
              background: $fluxoo-primary-color-hover;
              &::before{
                background: $fluxoo-primary-color;
                left: calc(100% - 25px);
              }
              &::after{
                content: 'Ativo';
                color: $fluxoo-primary-color;
              }
            }
          }
        }
        span{
          width: 43px;
          height: 11px;
          background: #949494;
          border-radius: 12px;
          position: relative;
          transition: background .2s;
          &::before{
            content: '';
            width: 25px;
            height: 25px;
            background: #D5D5D5;
            border-radius: 50%;
            position: absolute;
            top: -7px;
            left: 0;
            transition: background .2s, left .2s;
          }
          &::after{
            content: 'Inativo';
            position: absolute;
            left: calc(100% + 10px);
            bottom: -4px;
            color: #949494;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            transition: color .2s;
          }
        }
      }
    }
    nav{
      width: 100%;
      max-width: 1200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 32px;
      border-radius: 16px;
      background: #FFF;
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      position: sticky;
      bottom: 5px;
      column-gap: 20px;
      margin: auto 0 0 0;
      input{
        border: none;
        cursor: pointer;
        &:first-child{
          width: 100%;
          max-width: 464px;
          height: 60px;
          border-radius: 30px;
          background: $fluxoo-primary-color;
          color: #FFF;
          text-align: center;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          transition: background .2s;
          &:hover{
            background: $fluxoo-primary-color-hover;
          }
        }
        &:last-child{
          color: #737373;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          background: unset;
          transition: color .2s;
          &:hover{
            color: $fluxoo-primary-color;
          }
        }
      }
    }
  }

  @media(max-width: 425px){
    #create-organizations{
      padding: 25px 0 120px 0;
      nav{
        animation: enterToBottom ease 0.9s;
        width: 100vw;
        position: fixed;
        left: 0;
        bottom: 0;
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
        padding: 15px;
        box-sizing: border-box;
        z-index: 4;
      }
    }
  }
</style>
