<template>
    <div id="create-users">
      <section class="double">
        <section>
          <h1><strong>Nome</strong> (obrigatório)</h1>
          <input type="text" placeholder="Ex: João" v-model="payload.name">
        </section>
        <section>
          <h1><strong>Sobrenome</strong> (obrigatório)</h1>
          <input type="text" placeholder="Ex: da Silva" v-model="payload.last_name">
        </section>
      </section>
      <section class="double">
        <section>
          <h1><strong>E-mail</strong> (obrigatório)</h1>
          <input type="text" placeholder="Ex: João@gmail.com" v-model="payload.email">
        </section>
        <section>
          <h1><strong>Senha</strong> (obrigatório)</h1>
          <input type="text" placeholder="Pelo menos 6 caracteres" v-model="payload.password">
        </section>
      </section>
      <section>
        <h1><strong>Nível do usuário</strong> (obrigatório)</h1>
        <MySelect v-if="roles" :name="'role'" :for="'role'" :currentValue="payload.roles?.length ? payload.roles[0].name : null">
          <li v-for="(role, index) in allowedRoles" :key="index" @mousedown="payload.roles![0] = role;">{{ role.name }}</li>
        </MySelect>
        <SpinnerLoader v-else />
      </section>
      <section>
        <h1><strong>A qual setor pertence</strong> (obrigatório)</h1>
        <MySelectFilter v-if="organizations" :name="'organization'" :value="orgSearch" v-model="orgSearch" @blur="verifyValueOrganization">
          <li v-for="(organization, index) in organizations" :key="index" @mousedown="payload.organization = organization; orgSearch = organization.name">{{ organization.name }}</li>
        </MySelectFilter>
        <SpinnerLoader v-else />
      </section>
      <!-- <section>
        <h1><strong>Foto do usuário</strong></h1>
        <h2>Adicione um arquivo no formato PNG ou JPG.</h2>
        <FileUploader :files="documentFiles" :pushFile="(file) => documentFiles.push(file)" />
      </section> -->
      <section class="spacing">
        <h1><strong>Status</strong> (obrigatório)</h1>
        <label class="stats" for="stats">
          <input type="checkbox" name="stats" id="stats" v-model="payload.status">
          <span></span>
        </label>
      </section>
      <nav>
        <input v-if="!isCreatingUser" type="button" value="Criar" :disabled="!payload.name || !payload.last_name || !payload.email || payload.password!.length < 6 || !payload.roles!.length || !payload.organization" @click="createUser">
        <SpinnerLoaderButton v-else :height="60" :maxWidth="464"/>
        <input type="button" value="Cancelar" @click="$router.go(-1)">
      </nav>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import MySelect from '@/components/select/MySelect.vue'
import MySelectFilter from '@/components/select/MySelectFilter.vue'
import FileUploader from '@/components/input/FileUploader.vue'
import { useAxios } from '@/api/axios'
import { AxiosResponse } from 'axios'
import RoleModel from '@/models/RoleModel'
import { UserModel } from '@/models/UserModel'
import { OrganizationModel } from '@/models/OrganizationModel'
import SpinnerLoaderButton from '@/components/loader/SpinnerLoaderButton.vue'
import SpinnerLoader from '@/components/loader/SpinnerLoader.vue'
import { toast } from 'vue3-toastify'
import { user } from '@/global/states/GlobalState'

const axios = useAxios()
const payload = ref<Partial<UserModel>>({ roles: [], password: '' })
const roles = ref<RoleModel[]>()
const organizationsInitial = ref<OrganizationModel[]>()
const documentFiles = ref<File[]>([])
const isCreatingUser = ref(false)
const orgSearch = ref('')
const organizations = computed(() => {
  return organizationsInitial.value?.filter(organization =>
    organization.name.toLowerCase().includes(orgSearch.value.toLowerCase()) || !orgSearch.value
  )
})
const allowedRoles = computed(() => {
  return roles.value?.filter((role) => role.name == 'Super Admin' ? user.value?.hasRoles!(['Super Admin']) : true)
})

const verifyValueOrganization = (value: string) => {
  if (organizations.value) {
    let onClear = false
    for (const [index, options] of organizationsInitial.value!.entries()) {
      if (options.name === value) {
        onClear = true
      }
    }
    if (!onClear) {
      orgSearch.value = ''
      payload.value.organization = undefined
    }
  }
}

function getRoles (): void {
  axios.get('/api/roles')
    .then((res: AxiosResponse<RoleModel[]>) => {
      roles.value = res.data
    })
}

function getOrganizations (): void {
  axios.get('/api/organizations')
    .then((res: AxiosResponse<OrganizationModel[]>) => {
      organizationsInitial.value = res.data
    })
}

function createUser (): void {
  isCreatingUser.value = true

  const formData = new FormData()
  for (const key in payload.value) {
    if (payload.value[key]) {
      if (key == 'roles') {
        formData.append('roles[0]', payload.value.roles![0].name as string)
      } else if (key == 'image') {
        formData.append(key, payload.value[key] as string)
      } else if (key == 'status') {
        formData.append(key, payload.value[key] ? '1' : '0')
      } else {
        formData.append(key, String(payload.value[key]))
      }
    }
  }
  formData.append('organization_id', String(payload.value.organization!.id))

  axios.post('/api/users', formData)
    .then(() => {
      toast.success('Usuário criado com sucesso')
      payload.value = { roles: [], password: '' }
    })
    .finally(() => isCreatingUser.value = false)
}

onMounted(() => {
  getRoles()
  getOrganizations()
})
</script>

<style lang="scss">
  #create-users{
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 28px;
    padding: 25px 0 0 0;
    section{
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      &.double{
        display: flex;
        flex-direction: row;
        column-gap: 20px;
      }
      h1{
        color: #090909;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0 0 13px 0;
        text-align: start;
        strong{
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
      h2{
        color: #6F6F6F;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: start;
        margin: -13px 0 13px 0;
      }
      input[type="text"]{
        width: 100%;
        height: 55px;
        border-radius: 14px;
        border: 1px solid #D3D3D3;
        color: $fluxoo-primary-color;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 15px 20px;
        box-sizing: border-box;
        transition: border-color .2s, color .2s, background .2s;
        &:focus{
          outline: none;
        }
        &::placeholder{
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          transition: color .2s;
        }
      }
      .stats{
        width: 105px;
        display: flex;
        align-items: center;
        column-gap: 10px;
        cursor: pointer;
        input{
          display: none;
          &:checked{
            & ~ span{
              background: $fluxoo-primary-color-hover;
              &::before{
                background: $fluxoo-primary-color;
                left: calc(100% - 25px);
              }
              &::after{
                content: 'Ativo';
                color: $fluxoo-primary-color;
              }
            }
          }
        }
        span{
          width: 43px;
          height: 11px;
          background: #949494;
          border-radius: 12px;
          position: relative;
          transition: background .2s;
          &::before{
            content: '';
            width: 25px;
            height: 25px;
            background: #D5D5D5;
            border-radius: 50%;
            position: absolute;
            top: -7px;
            left: 0;
            transition: background .2s, left .2s;
          }
          &::after{
            content: 'Inativo';
            position: absolute;
            left: calc(100% + 10px);
            bottom: -4px;
            color: #949494;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            transition: color .2s;
          }
        }
      }
    }
    nav{
      width: 100%;
      max-width: 1200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 32px;
      border-radius: 16px;
      background: #FFF;
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      position: sticky;
      bottom: 5px;
      column-gap: 20px;
      margin: auto 0 0 0;
      input{
        border: none;
        cursor: pointer;
        &:first-child{
          width: 100%;
          max-width: 464px;
          height: 60px;
          border-radius: 30px;
          background: $fluxoo-primary-color;
          color: #FFF;
          text-align: center;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          transition: background .2s;
          &:hover{
            background: $fluxoo-primary-color-hover;
          }
        }
        &:last-child{
          color: #737373;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          background: unset;
          transition: color .2s;
          &:hover{
            color: $fluxoo-primary-color;
          }
        }
      }
    }
  }

  @media(max-width: 590px){
    #create-users{
      section{
        &.double{
          flex-direction: column !important;
          row-gap: 28px;
        }
      }
    }
  }

  @media(max-width: 425px){
    #create-users{
      padding: 25px 0 120px 0;
      nav{
        animation: enterToBottom ease 0.9s;
        width: 100vw;
        position: fixed;
        left: 0;
        bottom: 0;
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
        padding: 15px;
        box-sizing: border-box;
        z-index: 4;
      }
    }
  }
</style>
