import { ref } from 'vue'

const deferredPrompt = ref<any>()

export default function usePWA () {
  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault()

    const lastPromptDate: string | null = localStorage.getItem('pwaPromptLastShown')
    const now = new Date()
    const intervalDays = 2

    if (!lastPromptDate || (now.getTime() - new Date(lastPromptDate).getTime()) > intervalDays * 24 * 60 * 60 * 1000) {
      deferredPrompt.value = e
      localStorage.setItem('pwaPromptLastShown', now.toISOString())
    }
  })

  function install () {
    if (deferredPrompt.value) {
      deferredPrompt.value.prompt()
      deferredPrompt.value.userChoice.then(() => {
        deferredPrompt.value = null
      })
    }
  }

  return {
    install,
    deferredPrompt
  }
}
