import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginView from '@/views/auth/LoginView.vue'
import RecoverPasswordView from '@/views/auth/RecoverPasswordView.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'
import AdminLayout from '@/layouts/admin/AdminLayout.vue'
import HomeView from '@/views/admin/home/HomeView.vue'
import ViewDocument from '@/views/admin/documents/ViewDocument.vue'
import CreateDocuments from '@/views/admin/documents/CreateDocuments.vue'
import ManageDocuments from '@/views/admin/documents/ManageDocuments.vue'
import ResultsView from '@/views/admin/results/ResultsView.vue'
import ProfileView from '@/views/admin/profile/ProfileView.vue'
import HelpView from '@/views/admin/help/HelpView.vue'
import AlertsView from '@/views/admin/alerts/AlertsView.vue'

import CreateOrganizationsView from '@/views/admin/organizations/CreateOrganizationsView.vue'
import CreateModelsView from '@/views/admin/help/CreateModelsView.vue'
import ManageModelsView from '@/views/admin/help/ManageModelsView.vue'

import ManageUsersView from '@/views/admin/users/ManageUsersView.vue'
import CreateUsersView from '@/views/admin/users/CreateUsersView.vue'
import ShowUserView from '@/views/admin/users/ShowUserView.vue'
import UsersView from '@/views/admin/users/UsersView.vue'

import ManageOrganizationsView from '@/views/admin/organizations/ManageOrganizationsView.vue'

import CreateAlertsView from '@/views/admin/alerts/CreateAlertsView.vue'
import ManageAlertsView from '@/views/admin/alerts/ManageAlertsView.vue'
import EditOrganizationView from '@/views/admin/organizations/EditOrganizationView.vue'
import EditModelView from '@/views/admin/help/EditModelView.vue'
import EditAlertView from '@/views/admin/alerts/EditAlertView.vue'
import ManageDocumentSubjectsView from '@/views/admin/documentSubjects/ManageDocumentSubjectsView.vue'
import CreateDocumentSubjectsVIew from '@/views/admin/documentSubjects/CreateDocumentSubjectsVIew.vue'
import EditDocumentSubjectView from '@/views/admin/documentSubjects/EditDocumentSubjectView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/admin/home'
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/recover-password',
    name: 'recover-password',
    component: RecoverPasswordView
  },
  {
    path: '/password-reset/:token',
    name: 'password-reset',
    component: ResetPassword,
    props: true
  },
  {
    path: '/admin',
    component: AdminLayout,
    name: 'admin',
    children: [
      {
        path: 'home',
        component: HomeView,
        name: 'admin.home'
      },
      {
        path: 'create-documents',
        component: CreateDocuments
      },
      {
        path: 'manage-documents',
        component: ManageDocuments,
        name: 'admin.documents.index'
      },
      {
        path: 'view-document/:id',
        component: ViewDocument,
        name: 'admin.documents.show',
        props: true
      },
      {
        path: 'results/:search',
        component: ResultsView,
        name: 'admin.results',
        props: true
      },
      {
        path: 'help',
        component: HelpView,
        name: 'admin.help'
      },
      {
        path: 'alerts',
        component: AlertsView,
        name: 'admin.alerts'
      },
      {
        path: 'profile',
        component: ProfileView
      },
      {
        path: 'users',
        component: UsersView,
        name: 'admin.users.index'
      },

      {
        path: 'manage-users',
        component: ManageUsersView,
        name: 'admin.users.manage'
      },
      {
        path: 'create-users',
        component: CreateUsersView
      },
      {
        path: 'edit-user/:id',
        component: ShowUserView,
        name: 'admin.users.edit',
        props: true
      },

      {
        path: 'manage-organizations',
        component: ManageOrganizationsView
      },
      {
        path: 'create-organizations',
        component: CreateOrganizationsView
      },
      {
        path: 'edit-organization/:id',
        name: 'admin.organizations.edit',
        component: EditOrganizationView,
        props: true
      },

      {
        path: 'create-models',
        component: CreateModelsView
      },
      {
        path: 'manage-models',
        component: ManageModelsView
      },
      {
        path: 'edit-model/:id',
        name: 'admin.models.edit',
        props: true,
        component: EditModelView
      },

      {
        path: 'create-alerts',
        component: CreateAlertsView
      },
      {
        path: 'manage-alerts',
        component: ManageAlertsView
      },
      {
        path: 'edit-alert/:id',
        component: EditAlertView,
        name: 'admin.alerts.edit',
        props: true
      },

      // document subjects
      {
        path: 'create-document-subjects',
        component: CreateDocumentSubjectsVIew,
        name: 'admin.document-subjects.create'
      },
      {
        path: 'manage-document-subjects',
        component: ManageDocumentSubjectsView,
        name: 'admin.document-subjects.index'
      },
      {
        path: 'edit-document-subjects/:id',
        component: EditDocumentSubjectView,
        name: 'admin.document-subjects.edit',
        props: true
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

export default router
