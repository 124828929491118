import { useFxToast } from "@/plugins/FxToast";
import router from "@/router";

const fxToast = useFxToast()

export default class NotificationListener {
  public static listen(payload: any) {
    const actionHref = router.resolve({ name: 'admin.documents.show', params: { id: payload.document_id } }).href;

    fxToast.show(payload.title, payload.body, actionHref)
  }
}