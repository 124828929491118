<template>
  <div id="results">
      <label @click="$router.push('/admin/home')" class="back">
          <figure>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M9.57 5.93018L3.5 12.0002L9.57 18.0702M20.5 12.0002H3.67" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </figure>
          <p>Resultado busca</p>
      </label>
      <input autocomplete='off' type="radio" name="results" id="results-docs" checked>
      <input autocomplete='off' type="radio" name="results" id="results-organizations" @change="($event.target as HTMLInputElement).checked && !organizations ? getOrganizations() : ''">
      <input autocomplete='off' type="radio" name="results" id="results-users" @change="($event.target as HTMLInputElement).checked && !users ? getUsers() : ''">
      <main>
          <div class="tab">
            <label for="results-docs">Documentos</label>
            <label for="results-organizations">Setores</label>
            <label for="results-users">Usuários</label>
          </div>
          <section class="documents list">
            <p>Resultados para: <strong>DOCUMENTOS</strong></p>
            <SpinnerLoader v-if="loaders.documents" />
            <div class="no-result" v-if="!documentPagination?.data?.length && !loaders.documents">
              <svg xmlns="http://www.w3.org/2000/svg" width="173" height="173" viewBox="0 0 173 173" fill="none">
                <path opacity="0.4" d="M48.5143 142.004C54.4252 135.661 63.4354 136.165 68.6254 143.085L75.9059 152.817C81.7447 160.53 91.1875 160.53 97.0263 152.817L104.307 143.085C109.497 136.165 118.507 135.661 124.418 142.004C137.249 155.7 147.701 151.159 147.701 131.985V50.8186C147.701 21.769 140.925 14.4888 113.677 14.4888H59.1825C31.935 14.4888 25.1592 21.769 25.1592 50.8186V131.985C25.2313 151.159 35.7556 155.628 48.5143 142.004Z" fill="#292D32"/>
                <path d="M112.881 97.0963L107.547 91.7622C110.286 87.5814 111.944 82.5355 111.944 77.1293C111.944 62.4243 99.9782 50.4584 85.2732 50.4584C70.5682 50.4584 58.6025 62.4243 58.6025 77.1293C58.6025 91.8343 70.5682 103.8 85.2732 103.8C90.6795 103.8 95.7253 102.142 99.9061 99.403L105.24 104.737C106.322 105.818 107.691 106.323 109.061 106.323C110.43 106.323 111.8 105.818 112.881 104.737C114.972 102.647 114.972 99.1867 112.881 97.0963Z" fill="#292D32"/>
              </svg>
              <h1>Nada foi encontrado para sua busca</h1>
              <p><a @click="getDocuments()">Clique aqui</a> e tente novamente</p>
            </div>
            <section v-else-if="documentPagination" v-for="document in documentPagination.data" @click="$router.push({ name: 'admin.documents.show', params: { id: document.id } })">
              <h2>Ofício <strong>Nº {{ document.number }}</strong></h2>
              <h1>{{ document.title }}</h1>
              <h2>{{ document.organizations[0]?.name }}</h2>
            </section>
          </section>
          <section class="organizations list">
            <p>Resultados para: <strong>SETORES</strong></p>
            <SpinnerLoader v-if="loaders.organizations" />
            <div class="no-result" v-else-if="!organizations?.length && !loaders.organizations">
              <svg xmlns="http://www.w3.org/2000/svg" width="173" height="173" viewBox="0 0 173 173" fill="none">
                <path opacity="0.4" d="M48.5143 142.004C54.4252 135.661 63.4354 136.165 68.6254 143.085L75.9059 152.817C81.7447 160.53 91.1875 160.53 97.0263 152.817L104.307 143.085C109.497 136.165 118.507 135.661 124.418 142.004C137.249 155.7 147.701 151.159 147.701 131.985V50.8186C147.701 21.769 140.925 14.4888 113.677 14.4888H59.1825C31.935 14.4888 25.1592 21.769 25.1592 50.8186V131.985C25.2313 151.159 35.7556 155.628 48.5143 142.004Z" fill="#292D32"/>
                <path d="M112.881 97.0963L107.547 91.7622C110.286 87.5814 111.944 82.5355 111.944 77.1293C111.944 62.4243 99.9782 50.4584 85.2732 50.4584C70.5682 50.4584 58.6025 62.4243 58.6025 77.1293C58.6025 91.8343 70.5682 103.8 85.2732 103.8C90.6795 103.8 95.7253 102.142 99.9061 99.403L105.24 104.737C106.322 105.818 107.691 106.323 109.061 106.323C110.43 106.323 111.8 105.818 112.881 104.737C114.972 102.647 114.972 99.1867 112.881 97.0963Z" fill="#292D32"/>
              </svg>
              <h1>Nada foi encontrado para sua busca</h1>
              <p><a @click="getOrganizations()">Clique aqui</a> e tente novamente</p>
            </div>
            <section v-for="organization in organizations" @click="$router.push({ name: authenticatedUser?.can!('update_organizations') ? 'admin.organizations.edit' : 'admin.users.index', params: { id: organization.id } })">
              <h1>{{ organization.name }}</h1>
              <h2>{{ organization.acronym }}</h2>
            </section>
          </section>
          <section class="users">
            <p>Resultados para: <strong>USUÁRIOS</strong></p>
            <SpinnerLoader v-if="loaders.users" />
            <div class="no-result" v-else-if="!users?.length && !loaders.users">
              <svg xmlns="http://www.w3.org/2000/svg" width="173" height="173" viewBox="0 0 173 173" fill="none">
                <path opacity="0.4" d="M48.5143 142.004C54.4252 135.661 63.4354 136.165 68.6254 143.085L75.9059 152.817C81.7447 160.53 91.1875 160.53 97.0263 152.817L104.307 143.085C109.497 136.165 118.507 135.661 124.418 142.004C137.249 155.7 147.701 151.159 147.701 131.985V50.8186C147.701 21.769 140.925 14.4888 113.677 14.4888H59.1825C31.935 14.4888 25.1592 21.769 25.1592 50.8186V131.985C25.2313 151.159 35.7556 155.628 48.5143 142.004Z" fill="#292D32"/>
                <path d="M112.881 97.0963L107.547 91.7622C110.286 87.5814 111.944 82.5355 111.944 77.1293C111.944 62.4243 99.9782 50.4584 85.2732 50.4584C70.5682 50.4584 58.6025 62.4243 58.6025 77.1293C58.6025 91.8343 70.5682 103.8 85.2732 103.8C90.6795 103.8 95.7253 102.142 99.9061 99.403L105.24 104.737C106.322 105.818 107.691 106.323 109.061 106.323C110.43 106.323 111.8 105.818 112.881 104.737C114.972 102.647 114.972 99.1867 112.881 97.0963Z" fill="#292D32"/>
              </svg>
              <h1>Nada foi encontrado para sua busca</h1>
              <p><a @click="getUsers()">Clique aqui</a> e tente novamente</p>
            </div>
            <section>
              <div class="itens">
                <div class="item" v-for="user in users" @click="$router.push({ name: authenticatedUser?.can!('update_users') ? 'admin.users.edit' : 'admin.users.index', params: { id: user.id } })">
                  <h1>{{ user.name }}</h1>
                  <h2>{{ user.organization?.acronym ?? 'sem setor' }}</h2>
                </div>
              </div>
            </section>
          </section>
      </main>
  </div>
</template>

<script setup lang="ts">
import { useAxios } from '@/api/axios'
import SpinnerLoader from '@/components/loader/SpinnerLoader.vue'
import { DocumentModel } from '@/models/DocumentModel'
import { OrganizationModel } from '@/models/OrganizationModel'
import PaginationModel from '@/models/PaginationModel'
import { UserModel } from '@/models/UserModel'
import { AxiosResponse } from 'axios'
import { onMounted, ref } from 'vue'
import { user as authenticatedUser } from '@/global/states/GlobalState'

const props = defineProps<{search:string}>()
const loaders = ref({ documents: false, organizations: true, users: false })
const axios = useAxios()
const documentPagination = ref<PaginationModel<DocumentModel>>()
const organizations = ref<OrganizationModel[]>()
const users = ref<UserModel[]>()

onMounted(() => {
  getDocuments()
})

function getDocuments () {
  loaders.value.documents = true
  axios.get('/api/documents', { params: { search: props.search } })
    .then((res: AxiosResponse<PaginationModel<DocumentModel>>) => {
      documentPagination.value = res.data
    }).finally(() => loaders.value.documents = false)
}

function getOrganizations () {
  loaders.value.organizations = true
  axios.get('/api/organizations', { params: { search: props.search } })
    .then((res: AxiosResponse<OrganizationModel[]>) => {
      organizations.value = res.data
    }).finally(() => loaders.value.organizations = false)
}

function getUsers () {
  loaders.value.users = true
  axios.get('/api/users', { params: { search: props.search } })
    .then((res: AxiosResponse<UserModel[]>) => {
      users.value = res.data
    }).finally(() => loaders.value.users = false)
}
</script>

<style lang="scss">

  #results{
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0;
    animation: enterContent ease .4s;
    .back{
        display: flex;
        align-items: center;
        column-gap: 16px;
        cursor: pointer;
        margin: 0 auto 45px 0;
        box-sizing: border-box;
        &:hover{
            figure{
              background: $fluxoo-primary-color;
            }
            svg{
              stroke: #FFF;
            }
            p{
              color: $fluxoo-primary-color;
            }
        }
        figure{
            width: 40px;
            min-width: 40px;
            height: 40px;
            min-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            border-radius: 50px;
            background: #F5F5F5;
            transition: background .2s;
            svg{
            width: 24px;
            min-width: 24px;
            height: 24px;
            min-height: 24px;
            stroke: #090909;
            transition: stroke .2s;
            }
        }
        p{
            color: #090909;
            font-family: 'Nunito', sans-serif;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0;
            transition: color .2s
        }
    }
    input[type='radio']{
        display: none;
        &#results-docs:checked{
          & ~ main{
            .tab{
              label:nth-child(1){
                background: $fluxoo-primary-color;
                color: #FFF;
              }
            }
            .documents{
              display: flex;
            }
          }
        }
        &#results-organizations:checked{
          & ~ main{
            .tab{
              label:nth-child(2){
                background: $fluxoo-primary-color;
                color: #FFF;
              }
            }
            .organizations{
              display: flex;
            }
          }
        }
        &#results-users:checked{
          & ~ main{
            .tab{
              label:nth-child(3){
                background: $fluxoo-primary-color;
                color: #FFF;
              }
            }
            .users{
              display: flex;
            }
          }
        }
    }
    main{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      .tab{
        width: 100%;
        display: flex;
        align-content: center;
        column-gap: 15px;
        margin: 0 0 32px 0;
        box-sizing: border-box;
        label{
          height: 38px;
          padding: 20px;
          display: flex;
          align-items: center;
          color: #090909;
          font-family: 'Nunito', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.48px;
          background: #F1F1F1;
          border-radius: 30px;
          box-sizing: border-box;
          transition: background .2s, color .2s;
          cursor: pointer;
          &:hover{
              background: $fluxoo-primary-color;
              color: #FFF;
          }
        }
      }
      section{
        width: 100%;
        display: none;
        animation: enterContent ease .4s;
        box-sizing: border-box;
      }
      .list{
        width: 100%;
        flex-direction: column;
        align-items: start;
        p{
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0 0 20px 0;
        }
        section{
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          height: 85px;
          padding: 18px 24px;
          border-radius: 24px;
          border: 1px solid #F1F1F1;
          box-sizing: border-box;
          margin: 0 0 10px 0;
          transition: border-color .2s, background .2s;
          position: relative;
          cursor: pointer;
          &:before{
            content: '';
            width: 12px;
            height: 12px;
            border-right: 2px solid #090909;
            border-top: 2px solid #090909;
            transform: rotate(45deg);
            position: absolute;
            right: 30px;
            border-radius: 2px;
            transition: border-color .2s;
          }
          &:last-child{
            margin: 0 0 80px 0;
          }
          &:hover{
            border-color: $fluxoo-primary-color;
            background: $fluxoo-primary-color;
            h1{
              color: #FFF;
              text-decoration-line: underline;
            }
            h2{
              color: #FFF;
            }
            &::before{
              border-color: #FFF;
            }
          }
          h1{
            max-width: 85%;
            color: #090909;
            font-family: 'Nunito', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            white-space: normal;
            text-align: start;
            transition: color .2s;
          }
          h2{
            color: #090909;
            font-family: 'Inter', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0;
            transition: color .2s;
          }
        }
      }
      .users{
        width: 100%;
        flex-direction: column;
        align-items: start;
        p{
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0 0 20px 0;
        }
        section{
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: start;
          margin: 0 0 38px 0;
          &:last-child{
            margin: 0 0 50px 0;
          }
          p{
            margin: 0 0 5px 0;
          }
          .itens{
            width: 100%;
            display: grid;
            flex-direction: column;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            column-gap: 19px;
            row-gap: 10px;
            .item{
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: start;
              justify-content: center;
              height: 85px;
              padding: 18px 24px;
              border-radius: 24px;
              border: 1px solid #F1F1F1;
              box-sizing: border-box;
              transition: border-color .2s, background .2s;
              position: relative;
              cursor: pointer;
              &:before{
                content: '';
                width: 12px;
                height: 12px;
                border-right: 2px solid #090909;
                border-top: 2px solid #090909;
                transform: rotate(45deg);
                position: absolute;
                right: 30px;
                border-radius: 2px;
                transition: border-color .2s;
              }
              &:hover{
                border-color: $fluxoo-primary-color;
                background: $fluxoo-primary-color;
                h1{
                  color: #FFF;
                  text-decoration-line: underline;
                }
                h2{
                  color: #FFF;
                }
                &::before{
                  border-color: #FFF;
                }
              }
              h1{
                max-width: 85%;
                color: #090909;
                font-family: 'Nunito', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                white-space: normal;
                text-align: start;
                transition: color .2s;
              }
              h2{
                color: #090909;
                font-family: 'Inter', sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0;
                transition: color .2s;
              }
              &:last-child{
                margin: 0 0 30px 0;
              }
            }
          }
        }
      }
    }
    .no-result{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      margin: 50px 0 100px 0;
      svg{
        width: 173px;
        min-width: 173px;
        height: 173px;
        min-height: 173px;
      }
      h1{
        color:#080808;
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 24px 0 0 0 !important;
        text-align: center;
      }
      p{
        color: #080808;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 8px 0 0 0 !important;
        a{
          cursor: pointer;
          text-decoration-line: underline;
          color: $fluxoo-primary-color;
        }
      }
    }
  }

  @media(max-width: 490px){
    #results{
      main{
        .tab{
          overflow-y: auto;
          justify-content: start;
          &::-webkit-scrollbar{
            height: 0;
          }
        }
      }
    }
  }

  @media(max-width: 425px){
    #results{
      max-width: unset;
      .back{
        margin: 0 auto 40px 0;
      }
    }
  }
</style>
