<template>
  <div v-if="pwa.deferredPrompt.value" class="pwa-xl">
    <MyModal :isOpen="isInstallPwaModalOpen" :class="'pwa-modal'" :toggleModal="(v: boolean) => isInstallPwaModalOpen = v" :width="'806px'" :height="'113px'" :zIndex='10'>
      <div class="pwa">
        <div>
          <svg width="46" height="52" viewBox="0 0 46 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 6.47974C0 2.90108 2.90108 0 6.47974 0H39.5264C43.1051 0 46.0062 2.90108 46.0062 6.47974C46.0062 10.0584 43.1051 12.9595 39.5264 12.9595H6.47975C2.90109 12.9595 0 10.0584 0 6.47974Z" fill="#079F72"/>
            <path d="M0 22.679C0 19.1003 2.90108 16.1992 6.47974 16.1992H27.8629C31.4416 16.1992 34.3426 19.1003 34.3426 22.679C34.3426 26.2576 31.4415 29.1587 27.8629 29.1587H6.47974C2.90108 29.1587 0 26.2576 0 22.679Z" fill="#079F72"/>
            <rect y="32.3989" width="12.9595" height="19.6012" rx="6.47974" fill="#079F72"/>
          </svg>
          <section>
            <h1>Instale o Fluxoo</h1>
            <h2>Seu controle de documentos em poucos cliques, direto no seu computador.</h2>
          </section>
        </div>
        <div>
          <p @click="isInstallPwaModalOpen = false;">Agora não</p>
          <input type="button" value="instalar" @click="pwa.install(); isInstallPwaModalOpen = false">
        </div>
      </div>
    </MyModal>
  </div>
  <div v-if="pwa.deferredPrompt.value" class="pwa-sm">
    <MyModal :isOpen="isInstallPwaModalOpen" :class="'pwa-modal'" :toggleModal="(v: boolean) => isInstallPwaModalOpen = v" :height="'342px'" :zIndex='10'>
      <div class="pwa">
        <div>
          <svg width="46" height="52" viewBox="0 0 46 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 6.47974C0 2.90108 2.90108 0 6.47974 0H39.5264C43.1051 0 46.0062 2.90108 46.0062 6.47974C46.0062 10.0584 43.1051 12.9595 39.5264 12.9595H6.47975C2.90109 12.9595 0 10.0584 0 6.47974Z" fill="#079F72"/>
            <path d="M0 22.679C0 19.1003 2.90108 16.1992 6.47974 16.1992H27.8629C31.4416 16.1992 34.3426 19.1003 34.3426 22.679C34.3426 26.2576 31.4415 29.1587 27.8629 29.1587H6.47974C2.90108 29.1587 0 26.2576 0 22.679Z" fill="#079F72"/>
            <rect y="32.3989" width="12.9595" height="19.6012" rx="6.47974" fill="#079F72"/>
          </svg>
          <section>
            <h1>Instale o Fluxoo</h1>
            <h2>Seu controle de documentos em poucos cliques, direto no seu dispositivo.</h2>
          </section>
        </div>
        <div>
          <p @click="isInstallPwaModalOpen = false;">Agora não</p>
          <input type="button" value="instalar" @click="pwa.install(); isInstallPwaModalOpen = false">
        </div>
      </div>
    </MyModal>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import MyModal from '@/components/modal/MyModal.vue'
import usePWA from '@/hooks/usePWA'

const isInstallPwaModalOpen = ref<boolean>(true)
const pwa = usePWA()
</script>

<style lang="scss">
.modal{
  &.pwa-modal{
    align-items: end;
    padding: 24px 15px;
    .modal__content{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      .pwa{
        width: 100%;
        margin: auto 0;
        padding: 15px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
        div{
          &:nth-child(1){
            min-width: 270px;
            display: flex;
            align-items: center;
            column-gap: 20px;
            svg{
              width: 46px;
              min-width: 46px;
              height: 52px;
              min-height: 52px;
            }
            section{
              max-width: 248px;
              display: flex;
              flex-direction: column;
              h1{
                color: #292F31;
                font-family: 'Nunito', sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
                margin: 0;
              }
              h2{
                color: #292F31;
                font-family: 'Inter', sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0;
              }
            }
          }
          &:nth-child(2){
            width: 100%;
            max-width: 350px;
            display: flex;
            align-items: center;
            column-gap: 25px;
            p{
              min-width: 63px;
              color: #292F31;
              text-align: center;
              font-family: 'Inter', sans-serif;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin: 0;
              cursor: pointer;
              transition: color .2s;
              &:hover{
                color: $fluxoo-primary-color;
              }
            }
            input{
              width: 100%;
              max-width: 260px;
              height: 55px;
              cursor: pointer;
              border: none;
              border-radius: 30px;
              background: $fluxoo-primary-color;
              color: #FFF;
              text-align: center;
              font-family: 'Inter', sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 800;
              line-height: normal;
              transition: background .2s;
              &:hover{
                background: $fluxoo-primary-color-hover;
              }
            }
          }
        }
      }
    }
  }
}

.pwa-sm{
  display: none;
}

@media(max-width: 570px){
  .pwa-xl{
    display: none;
  }
  .pwa-sm{
    display: flex;
  }
  .modal{
    &.pwa-modal{
      padding: 0;
      .modal__content{
        max-height: 342px !important;
        border-radius: 24px 24px 0 0 !important;
        .pwa{
          row-gap: 43px;
          flex-direction: column;
          div{
            &:nth-child(1){
              flex-direction: column;
              row-gap: 18px;
              section{
                max-width: unset;
                text-align: center;
                h1{
                  margin: 0 0 7px 0;
                }
              }
            }
            &:nth-child(2){
              row-gap: 26px;
              flex-direction: column;
              p{
                order: 2;
              }
              input{
                order: 1;
              }
            }
          }
        }
      }
    }
  }
}
</style>