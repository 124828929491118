<template>
  <nav v-if="true" class="desktop" :class="{ open: menuOpen }">
      <img title="Voltar para home" src="@/assets/images/prefeitura-caninde.png" alt="Logo Prefeitura de Canindé." @click="menuOpen = false; checkMenuIsOpen(); $router.push('/admin/home')">
      <button title="Abrir menu" class="menu" @click="menuOpenOrClose();">
          <figure>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M2.5 5.8335H17.5" stroke-width="1.5" stroke-linecap="round"/>
                  <path d="M2.5 10H17.5" stroke-width="1.5" stroke-linecap="round"/>
                  <path d="M2.5 14.1665H17.5" stroke-width="1.5" stroke-linecap="round"/>
              </svg>
          </figure>
      </button>
      <button title="Home" id="home-menu" :class="{ 'active': $route.path === '/admin/home' || $route.path === '/admin/results' }" @click="$router.push('/admin/home'); menuOpen = false; checkMenuIsOpen(); closeAllOptions();">
          <figure>
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 15.5V13" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.39172 2.85004L2.61672 7.47504C1.96672 7.9917 1.55006 9.08337 1.69172 9.90004L2.80006 16.5334C3.00006 17.7167 4.13339 18.675 5.33339 18.675H14.6667C15.8584 18.675 17.0001 17.7084 17.2001 16.5334L18.3084 9.90004C18.4417 9.08337 18.0251 7.9917 17.3834 7.47504L11.6084 2.85837C10.7167 2.1417 9.27506 2.1417 8.39172 2.85004Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </figure>
      </button>
      <button title="Documentos" id="documents-menu" v-if="optionsListDocuments.length" class="options" :class="{ 'active': $route.path === '/admin/create-documents' || $route.path === '/admin/manage-documents' || $route.path === '/admin/exceptions-documents' || $route.path.includes('/admin/view-document'), opened: optionListDocuments }" @click="optionListDocuments = !optionListDocuments; openListOptions('documents');">
          <figure>
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="vuesax/linear/folder">
                      <g id="folder">
                          <path id="Vector" d="M18.3334 9.6665V14.6665C18.3334 17.9998 17.5001 18.8332 14.1667 18.8332H5.83341C2.50008 18.8332 1.66675 17.9998 1.66675 14.6665V6.33317C1.66675 2.99984 2.50008 2.1665 5.83341 2.1665H7.08341C8.33342 2.1665 8.60841 2.53317 9.08341 3.1665L10.3334 4.83317C10.6501 5.24984 10.8334 5.49984 11.6667 5.49984H14.1667C17.5001 5.49984 18.3334 6.33317 18.3334 9.6665Z" stroke-width="1.5" stroke-miterlimit="10"/>
                      </g>
                  </g>
              </svg>
          </figure>
      </button>
      <ul v-if="optionsListDocuments.length" :class="{ opened: optionListDocuments }">
          <li v-for="(option, index) in optionsListDocuments" :class="{ 'selected': $route.path === `/admin/${option.router}` }" @click="$router.push(`/admin/${option.router}`); menuOpenOrClose(); closeAllOptions();">
              <p>{{ option.name }}</p>
              <span></span>
          </li>
      </ul>
      <button v-if="optionsListDocumentSubjects.length" title="Assuntos de documento" id="document-subjects-menu" class="options" :class="{ 'active': $route.path.includes('document-subject'), opened: optionListDocumentsSubjects }" @click="optionListDocumentsSubjects = !optionListDocumentsSubjects; openListOptions('documents-subjects');">
          <figure>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 12.2H15"  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8 16.2H12.38"  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10 6H14C16 6 16 5 16 4C16 2 15 2 14 2H10C9 2 8 2 8 4C8 6 9 6 10 6Z"  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16 4.02002C19.33 4.20002 21 5.43002 21 10V16C21 20 20 22 15 22H9C4 22 3 20 3 16V10C3 5.44002 4.67 4.20002 8 4.02002" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </figure>
      </button>
      <ul v-if="optionsListDocumentSubjects.length" :class="{ opened: optionListDocumentsSubjects }">
          <li v-for="(option, index) in optionsListDocumentSubjects" :class="{ 'selected': $route.path === `/admin/${option.path}` }" @click="$router.push(`/admin/${option.path}`); menuOpenOrClose(); closeAllOptions();">
              <p>{{ option.name }}</p>
              <span></span>
          </li>
      </ul>
      <button title="Modelos" id="models-menu" v-if="optionsListModels.length > 1" class="options" :class="{ 'active': $route.path === '/admin/create-models' || $route.path === '/admin/manage-models' || $route.path.includes('/admin/edit-model') || $route.path === '/admin/help', opened: optionListModels }" @click="optionListModels = !optionListModels; openListOptions('models');">
          <figure>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5013 18.3332H12.5013C16.668 18.3332 18.3346 16.6665 18.3346 12.4998V7.49984C18.3346 3.33317 16.668 1.6665 12.5013 1.6665H7.5013C3.33464 1.6665 1.66797 3.33317 1.66797 7.49984V12.4998C1.66797 16.6665 3.33464 18.3332 7.5013 18.3332Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.125 7.5H6.875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.125 12.5H6.875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </figure>
      </button>
      <ul v-if="optionsListModels.length > 1" :class="{ opened: optionListModels }">
          <li v-for="(option, index) in optionsListModels" :class="{ 'selected': $route.path === `/admin/${option.router}`}" @click="$router.push(`/admin/${option.router}`); menuOpenOrClose(); closeAllOptions();">
              <p>{{ option.name }}</p>
              <span></span>
          </li>
      </ul>
      <button title="Ajuda" id="help" v-else :class="{ 'active': $route.path === '/admin/help' }" @click="$router.push('/admin/help'); menuOpen = false; checkMenuIsOpen(); closeAllOptions();"><figure><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="SVG namespace "><path d="M7.5013 18.3332H12.5013C16.668 18.3332 18.3346 16.6665 18.3346 12.4998V7.49984C18.3346 3.33317 16.668 1.6665 12.5013 1.6665H7.5013C3.33464 1.6665 1.66797 3.33317 1.66797 7.49984V12.4998C1.66797 16.6665 3.33464 18.3332 7.5013 18.3332Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.125 7.5H6.875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.125 12.5H6.875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></figure></button>
      <button title="Setores" id="sectors-menu" v-if="optionsListSectors.length" class="options" :class="{ 'active': $route.path === '/admin/create-organizations' || $route.path === '/admin/manage-organizations' || $route.path.includes('/admin/edit-organization'), opened: optionListSectors }" @click="optionListSectors = !optionListSectors; openListOptions('sectors');">
          <figure>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.833252 18.3335H19.1666" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M16.4844 18.3417V14.625" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M16.5013 9.0752C15.4846 9.0752 14.668 9.89187 14.668 10.9085V12.8002C14.668 13.8168 15.4846 14.6335 16.5013 14.6335C17.518 14.6335 18.3347 13.8168 18.3347 12.8002V10.9085C18.3347 9.89187 17.518 9.0752 16.5013 9.0752Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M1.75122 18.3333V5.02498C1.75122 3.34998 2.5846 2.5083 4.24293 2.5083H9.43458C11.0929 2.5083 11.9179 3.34998 11.9179 5.02498V18.3333" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M4.83398 6.875H8.95899" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M4.83398 10H8.95899" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6.875 18.3335V15.2085" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </figure>
      </button>
      <ul v-if="optionsListSectors.length" :class="{ opened: optionListSectors }">
          <li v-for="(option, index) in optionsListSectors" :class="{ 'selected': $route.path === `/admin/${option.router}` }" @click="$router.push(`/admin/${option.router}`); menuOpenOrClose(); closeAllOptions();">
              <p>{{ option.name }}</p>
              <span></span>
          </li>
      </ul>
      <button title="Usuários" id="users-menu" v-if="optionsListUsers.length" class="options" :class="{ 'active': $route.path === '/admin/create-users' || $route.path === '/admin/manage-users' || $route.path.includes('/admin/edit-user'), opened: optionListUsers }" @click="optionListUsers = !optionListUsers; openListOptions('users');">
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M14.9999 5.96651C14.9499 5.95817 14.8916 5.95817 14.8416 5.96651C13.6916 5.92484 12.7749 4.98317 12.7749 3.81651C12.7749 2.62484 13.7332 1.6665 14.9249 1.6665C16.1166 1.6665 17.0749 2.63317 17.0749 3.81651C17.0666 4.98317 16.1499 5.92484 14.9999 5.96651Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M14.1415 12.0333C15.2831 12.225 16.5415 12.025 17.4248 11.4333C18.5998 10.65 18.5998 9.36664 17.4248 8.58331C16.5331 7.99164 15.2581 7.79163 14.1165 7.99163"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4.9749 5.96651C5.0249 5.95817 5.08324 5.95817 5.13324 5.96651C6.28324 5.92484 7.1999 4.98317 7.1999 3.81651C7.1999 2.62484 6.24157 1.6665 5.0499 1.6665C3.85824 1.6665 2.8999 2.63317 2.8999 3.81651C2.90824 4.98317 3.8249 5.92484 4.9749 5.96651Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.83328 12.0333C4.69162 12.225 3.43328 12.025 2.54995 11.4333C1.37495 10.65 1.37495 9.36664 2.54995 8.58331C3.44162 7.99164 4.71662 7.79163 5.85828 7.99163"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.0001 12.1916C9.95015 12.1833 9.89181 12.1833 9.84181 12.1916C8.69181 12.1499 7.77515 11.2083 7.77515 10.0416C7.77515 8.84994 8.73348 7.8916 9.92514 7.8916C11.1168 7.8916 12.0751 8.85827 12.0751 10.0416C12.0668 11.2083 11.1501 12.1583 10.0001 12.1916Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.5751 14.8168C6.4001 15.6001 6.4001 16.8835 7.5751 17.6668C8.90843 18.5585 11.0918 18.5585 12.4251 17.6668C13.6001 16.8835 13.6001 15.6001 12.4251 14.8168C11.1001 13.9335 8.90843 13.9335 7.5751 14.8168Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </figure>
      </button>
      <ul v-if="optionsListUsers.length" :class="{ opened: optionListUsers }">
          <li v-for="(option, index) in optionsListUsers" :class="{ 'selected': $route.path === `/admin/${option.router}` }" @click="$router.push(`/admin/${option.router}`); menuOpenOrClose(); closeAllOptions();">
              <p>{{ option.name }}</p>
              <span></span>
          </li>
      </ul>
      <button title="Usuários" id="users-menu" v-else :class="{ 'active': $route.path === '/admin/users' }" @click="$router.push('/admin/users'); menuOpen = false; checkMenuIsOpen(); closeAllOptions();">
        <figure>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 5.96651C14.95 5.95817 14.8917 5.95817 14.8417 5.96651C13.6917 5.92484 12.775 4.98317 12.775 3.81651C12.775 2.62484 13.7334 1.6665 14.925 1.6665C16.1167 1.6665 17.075 2.63317 17.075 3.81651C17.0667 4.98317 16.15 5.92484 15 5.96651Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.1417 12.0333C15.2834 12.225 16.5417 12.025 17.425 11.4333C18.6 10.65 18.6 9.36664 17.425 8.58331C16.5334 7.99164 15.2584 7.79163 14.1167 7.99163" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.9749 5.96651C5.0249 5.95817 5.08324 5.95817 5.13324 5.96651C6.28324 5.92484 7.1999 4.98317 7.1999 3.81651C7.1999 2.62484 6.24157 1.6665 5.0499 1.6665C3.85824 1.6665 2.8999 2.63317 2.8999 3.81651C2.90824 4.98317 3.8249 5.92484 4.9749 5.96651Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.83328 12.0333C4.69162 12.225 3.43328 12.025 2.54995 11.4333C1.37495 10.65 1.37495 9.36664 2.54995 8.58331C3.44162 7.99164 4.71662 7.79163 5.85828 7.99163" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 12.1916C9.95002 12.1833 9.89169 12.1833 9.84169 12.1916C8.69169 12.1499 7.77502 11.2083 7.77502 10.0416C7.77502 8.84994 8.73336 7.8916 9.92502 7.8916C11.1167 7.8916 12.075 8.85827 12.075 10.0416C12.0667 11.2083 11.15 12.1583 10 12.1916Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.57498 14.8168C6.39998 15.6001 6.39998 16.8835 7.57498 17.6668C8.90831 18.5585 11.0916 18.5585 12.425 17.6668C13.6 16.8835 13.6 15.6001 12.425 14.8168C11.1 13.9335 8.90831 13.9335 7.57498 14.8168Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </figure>
      </button>
      <button title="Alertas" id="alerts-menu" v-if="optionsListAlerts.length > 1" class="options" :class="{ 'active': $route.path === '/admin/create-alerts' || $route.path === '/admin/manage-alerts' || $route.path.includes('/admin/edit-alert'), opened: optionListAlerts }" @click="optionListAlerts = !optionListAlerts; openListOptions('alerts');">
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M10 6.4585V10.8335"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M17.5668 7.14987V12.8498C17.5668 13.7832 17.0667 14.6499 16.2584 15.1249L11.3084 17.9832C10.5001 18.4499 9.50006 18.4499 8.68339 17.9832L3.73339 15.1249C2.92505 14.6582 2.42505 13.7915 2.42505 12.8498V7.14987C2.42505 6.21653 2.92505 5.34983 3.73339 4.87483L8.68339 2.0165C9.49172 1.54984 10.4917 1.54984 11.3084 2.0165L16.2584 4.87483C17.0667 5.34983 17.5668 6.2082 17.5668 7.14987Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10 13.5V13.5833"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </figure>
      </button>
      <ul v-if="optionsListAlerts.length > 1" :class="{ opened: optionListAlerts }">
          <li v-for="(option, index) in optionsListAlerts" :class="{ 'selected': $route.path === `/admin/${option.router}` }" @click="$router.push(`/admin/${option.router}`); menuOpenOrClose(); closeAllOptions();">
              <p>{{ option.name }}</p>
              <span></span>
          </li>
      </ul>
      <button title="Alertas" id="alerts-menu-normal" v-else-if="optionsListAlerts.length == 1" :class="{ 'active': $route.path === '/admin/alerts' }" @click="$router.push('/admin/alerts'); menuOpen = false; checkMenuIsOpen(); closeAllOptions();">
          <figure>
            <svg class="alerts" xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
              <path d="M10.5 8.4585V12.8335" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M18.0668 9.14987V14.8498C18.0668 15.7832 17.5667 16.6499 16.7584 17.1249L11.8084 19.9832C11.0001 20.4499 10.0001 20.4499 9.18339 19.9832L4.23339 17.1249C3.42505 16.6582 2.92505 15.7915 2.92505 14.8498V9.14987C2.92505 8.21653 3.42505 7.34983 4.23339 6.87483L9.18339 4.0165C9.99172 3.54984 10.9917 3.54984 11.8084 4.0165L16.7584 6.87483C17.5667 7.34983 18.0668 8.2082 18.0668 9.14987Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.5 15.5V15.5833" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <!-- IF DE QUANDO TIVER UMA NOVA NOTIFICAÇÃO AQUI EM BAIXO -->
              <circle v-if="false" cx="17.5" cy="6" r="6" fill="#A30711" stroke='none'/>
            </svg>
          </figure>
      </button>
      <div></div>
      <button title="Perfil" id="profile-menu" :class="{ 'active': $route.path === '/admin/profile' }" @click="$router.push('/admin/profile'); menuOpen = false; checkMenuIsOpen(); closeAllOptions();">
          <figure>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="vuesax/linear/profile-circle">
                      <g id="profile-circle">
                          <path id="Vector" d="M10.0999 10.6502C10.0416 10.6419 9.9666 10.6419 9.89994 10.6502C8.43327 10.6002 7.2666 9.40023 7.2666 7.92523C7.2666 6.41689 8.48327 5.19189 9.99993 5.19189C11.5083 5.19189 12.7333 6.41689 12.7333 7.92523C12.7249 9.40023 11.5666 10.6002 10.0999 10.6502Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path id="Vector_2" d="M15.6166 16.1498C14.1333 17.5081 12.1666 18.3331 9.99997 18.3331C7.8333 18.3331 5.86663 17.5081 4.3833 16.1498C4.46663 15.3665 4.96663 14.5998 5.8583 13.9998C8.14163 12.4831 11.875 12.4831 14.1416 13.9998C15.0333 14.5998 15.5333 15.3665 15.6166 16.1498Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path id="Vector_3" d="M10.0001 18.3332C14.6025 18.3332 18.3334 14.6022 18.3334 9.99984C18.3334 5.39746 14.6025 1.6665 10.0001 1.6665C5.39771 1.6665 1.66675 5.39746 1.66675 9.99984C1.66675 14.6022 5.39771 18.3332 10.0001 18.3332Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                  </g>
              </svg>
          </figure>
      </button>
      <button title="Desconectar" id="logout-menu" @click="logout">
          <figure>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.4417 14.62L20.0017 12.06L17.4417 9.5M9.76172 12.06H19.9317M11.7617 20C7.34172 20 3.76172 17 3.76172 12C3.76172 7 7.34172 4 11.7617 4" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </figure>
      </button>
  </nav>
  <nav v-if="true" class="mobile" :class="{ opened: optionListDocuments || optionListMenu, documents: optionListDocuments, menu: optionListMenu}">
      <ul class="options-documents">
          <li>
              <h1>Documentos</h1>
              <svg @click="optionListDocuments = !optionListDocuments; checkMenuIsOpen();" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M18 6L6 18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6 6L18 18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </li>
          <li v-for="(option, index) in optionsListDocuments" :class="{ 'selected': $route.path === `/admin/${option.router}` }" @click="$router.push(`/admin/${option.router}`); optionListDocuments = false; checkMenuIsOpen();">
              <p>{{ option.name }}</p>
              <span></span>
          </li>
      </ul>
      <ul class="options-mobile">
        <h1>Menu</h1>
        <li>
          <div class="item" :class="{ 'active': $route.path === '/admin/home' || $route.path === '/admin/results' }" @click="$router.push(`/admin/home`);">
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10 15V12.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.39172 2.35004L2.61672 6.97504C1.96672 7.4917 1.55006 8.58337 1.69172 9.40004L2.80006 16.0334C3.00006 17.2167 4.13339 18.175 5.33339 18.175H14.6667C15.8584 18.175 17.0001 17.2084 17.2001 16.0334L18.3084 9.40004C18.4417 8.58337 18.0251 7.4917 17.3834 6.97504L11.6084 2.35837C10.7167 1.6417 9.27506 1.6417 8.39172 2.35004Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </figure>
            <p>Home</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path d="M4.58004 9.41016L11.1 15.9302C11.87 16.7002 13.13 16.7002 13.9 15.9302L20.42 9.41016" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </li>
        <li v-if="optionsListDocuments.length" class="options">
          <div class="item" :class="{ 'active': $route.path === '/admin/create-documents' || $route.path === '/admin/manage-documents' || $route.path === '/admin/exceptions-documents' || $route.path === '/admin/document', opened: optionMobileListDocuments }" @click="openListOptionsMobile('documents')">
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M18.3333 9.1665V14.1665C18.3333 17.4998 17.5 18.3332 14.1666 18.3332H5.83329C2.49996 18.3332 1.66663 17.4998 1.66663 14.1665V5.83317C1.66663 2.49984 2.49996 1.6665 5.83329 1.6665H7.08329C8.33329 1.6665 8.60829 2.03317 9.08329 2.6665L10.3333 4.33317C10.65 4.74984 10.8333 4.99984 11.6666 4.99984H14.1666C17.5 4.99984 18.3333 5.83317 18.3333 9.1665Z" stroke-width="1.5" stroke-miterlimit="10"/>
              </svg>
            </figure>
            <p>Documentos</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path d="M4.58004 9.41016L11.1 15.9302C11.87 16.7002 13.13 16.7002 13.9 15.9302L20.42 9.41016" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="options">
            <p v-for="option in optionsListDocuments" :class="{ 'active' : $route.path === `/admin/${option.router}`}" @click="$router.push(`/admin/${option.router}`); closeAllOptionsMobile(); optionListMenu = !optionListMenu; optionListDocuments = false; checkMenuIsOpen();">{{ option.name }}</p>
          </div>
        </li>
        <li v-if="optionsListDocumentSubjects.length" class="options">
          <div class="item" :class="{ 'active': $route.path.includes('document-subject'), opened: optionMobileListDocumentsSubjects }" @click="openListOptionsMobile('documents-subjects')">
            <figure>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 12.2H15"  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 16.2H12.38"  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 6H14C16 6 16 5 16 4C16 2 15 2 14 2H10C9 2 8 2 8 4C8 6 9 6 10 6Z"  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 4.02002C19.33 4.20002 21 5.43002 21 10V16C21 20 20 22 15 22H9C4 22 3 20 3 16V10C3 5.44002 4.67 4.20002 8 4.02002" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </figure>
            <p>Assuntos</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path d="M4.58004 9.41016L11.1 15.9302C11.87 16.7002 13.13 16.7002 13.9 15.9302L20.42 9.41016" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="options">
            <p v-for="option in optionsListDocumentSubjects" :class="{ 'active' : $route.path === `/admin/${option.path}`}" @click="$router.push(`/admin/${option.path}`); closeAllOptionsMobile(); optionListMenu = !optionListMenu; optionListDocuments = false; checkMenuIsOpen();">{{ option.name }}</p>
          </div>
        </li>
        <li v-if="optionsListModels.length" class="options">
          <div class="item" :class="{ 'active': $route.path === '/admin/create-models' || $route.path === '/admin/manage-models' || $route.path.includes('/admin/edit-model') || $route.path === '/admin/help', opened: optionMobileListModels }" @click="openListOptionsMobile('models')">
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M7.5013 18.3332H12.5013C16.668 18.3332 18.3346 16.6665 18.3346 12.4998V7.49984C18.3346 3.33317 16.668 1.6665 12.5013 1.6665H7.5013C3.33464 1.6665 1.66797 3.33317 1.66797 7.49984V12.4998C1.66797 16.6665 3.33464 18.3332 7.5013 18.3332Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.125 7.5H6.875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.125 12.5H6.875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </figure>
            <p>Modelos</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path d="M4.58004 9.41016L11.1 15.9302C11.87 16.7002 13.13 16.7002 13.9 15.9302L20.42 9.41016" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="options">
            <p v-for="option in optionsListModels" :class="{ 'active' : $route.path === `/admin/${option.router}`}" @click="$router.push(`/admin/${option.router}`); closeAllOptionsMobile(); optionListMenu = !optionListMenu; checkMenuIsOpen();">{{ option.name }}</p>
          </div>
        </li>
        <li @click="$router.push({ name: 'admin.help' }); optionListMenu = false; checkMenuIsOpen();" v-else class="options">
          <div class="item" :class="{ 'active': $route.path === '/admin/help', opened: false }">
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M7.5013 18.3332H12.5013C16.668 18.3332 18.3346 16.6665 18.3346 12.4998V7.49984C18.3346 3.33317 16.668 1.6665 12.5013 1.6665H7.5013C3.33464 1.6665 1.66797 3.33317 1.66797 7.49984V12.4998C1.66797 16.6665 3.33464 18.3332 7.5013 18.3332Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.125 7.5H6.875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.125 12.5H6.875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </figure>
            <p>Ajuda</p>
          </div>
        </li>
        <li v-if="optionsListSectors.length" class="options">
          <div class="item" :class="{ 'active': $route.path === '/admin/create-organizations' || $route.path === '/admin/manage-organizations' || $route.path.includes('/admin/edit-organization'), opened: optionMobileListSectors }" @click="openListOptionsMobile('sectors')">
            <figure>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.833252 18.3335H19.1666" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.4844 18.3417V14.625" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.5013 9.0752C15.4846 9.0752 14.668 9.89187 14.668 10.9085V12.8002C14.668 13.8168 15.4846 14.6335 16.5013 14.6335C17.518 14.6335 18.3347 13.8168 18.3347 12.8002V10.9085C18.3347 9.89187 17.518 9.0752 16.5013 9.0752Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1.75122 18.3333V5.02498C1.75122 3.34998 2.5846 2.5083 4.24293 2.5083H9.43458C11.0929 2.5083 11.9179 3.34998 11.9179 5.02498V18.3333" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.83398 6.875H8.95899" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.83398 10H8.95899" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.875 18.3335V15.2085" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </figure>
            <p>Setores</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path d="M4.58004 9.41016L11.1 15.9302C11.87 16.7002 13.13 16.7002 13.9 15.9302L20.42 9.41016" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="options">
            <p v-for="option in optionsListSectors" :class="{ 'active' : $route.path === `/admin/${option.router}`}" @click="$router.push(`/admin/${option.router}`); closeAllOptionsMobile(); optionListMenu = !optionListMenu; checkMenuIsOpen();">{{ option.name }}</p>
          </div>
        </li>
        <li class="options" v-if="optionsListUsers.length">
          <div class="item" :class="{ 'active': $route.path === '/admin/create-users' || $route.path === '/admin/manage-users' || $route.path.includes('/admin/edit-user'), opened: optionMobileListUsers }" @click="openListOptionsMobile('users')">
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M15 5.96651C14.95 5.95817 14.8917 5.95817 14.8417 5.96651C13.6917 5.92484 12.775 4.98317 12.775 3.81651C12.775 2.62484 13.7334 1.6665 14.925 1.6665C16.1167 1.6665 17.075 2.63317 17.075 3.81651C17.0667 4.98317 16.15 5.92484 15 5.96651Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.1417 12.0333C15.2834 12.225 16.5417 12.025 17.425 11.4333C18.6 10.65 18.6 9.36664 17.425 8.58331C16.5334 7.99164 15.2584 7.79163 14.1167 7.99163" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.9749 5.96651C5.0249 5.95817 5.08324 5.95817 5.13324 5.96651C6.28324 5.92484 7.1999 4.98317 7.1999 3.81651C7.1999 2.62484 6.24157 1.6665 5.0499 1.6665C3.85824 1.6665 2.8999 2.63317 2.8999 3.81651C2.90824 4.98317 3.8249 5.92484 4.9749 5.96651Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.83328 12.0333C4.69162 12.225 3.43328 12.025 2.54995 11.4333C1.37495 10.65 1.37495 9.36664 2.54995 8.58331C3.44162 7.99164 4.71662 7.79163 5.85828 7.99163" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 12.1916C9.95002 12.1833 9.89169 12.1833 9.84169 12.1916C8.69169 12.1499 7.77502 11.2083 7.77502 10.0416C7.77502 8.84994 8.73336 7.8916 9.92502 7.8916C11.1167 7.8916 12.075 8.85827 12.075 10.0416C12.0667 11.2083 11.15 12.1583 10 12.1916Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.57498 14.8168C6.39998 15.6001 6.39998 16.8835 7.57498 17.6668C8.90831 18.5585 11.0916 18.5585 12.425 17.6668C13.6 16.8835 13.6 15.6001 12.425 14.8168C11.1 13.9335 8.90831 13.9335 7.57498 14.8168Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </figure>
            <p>Usuários</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path d="M4.58004 9.41016L11.1 15.9302C11.87 16.7002 13.13 16.7002 13.9 15.9302L20.42 9.41016" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="options">
            <p v-for="option in optionsListUsers" :class="{ 'active': $route.path === `/admin/${option.router}`}" @click="$router.push(`/admin/${option.router}`); closeAllOptionsMobile(); optionListMenu = !optionListMenu; checkMenuIsOpen();">{{ option.name }}</p>
          </div>
        </li>
        <li @click="$router.push('/admin/users'); optionListMenu = false; checkMenuIsOpen();" v-else class="options">
          <div class="item" :class="{ 'active': $route.path === '/admin/users', opened: false }">
            <figure>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 5.96651C14.95 5.95817 14.8917 5.95817 14.8417 5.96651C13.6917 5.92484 12.775 4.98317 12.775 3.81651C12.775 2.62484 13.7334 1.6665 14.925 1.6665C16.1167 1.6665 17.075 2.63317 17.075 3.81651C17.0667 4.98317 16.15 5.92484 15 5.96651Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.1417 12.0333C15.2834 12.225 16.5417 12.025 17.425 11.4333C18.6 10.65 18.6 9.36664 17.425 8.58331C16.5334 7.99164 15.2584 7.79163 14.1167 7.99163" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.9749 5.96651C5.0249 5.95817 5.08324 5.95817 5.13324 5.96651C6.28324 5.92484 7.1999 4.98317 7.1999 3.81651C7.1999 2.62484 6.24157 1.6665 5.0499 1.6665C3.85824 1.6665 2.8999 2.63317 2.8999 3.81651C2.90824 4.98317 3.8249 5.92484 4.9749 5.96651Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.83328 12.0333C4.69162 12.225 3.43328 12.025 2.54995 11.4333C1.37495 10.65 1.37495 9.36664 2.54995 8.58331C3.44162 7.99164 4.71662 7.79163 5.85828 7.99163" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 12.1916C9.95002 12.1833 9.89169 12.1833 9.84169 12.1916C8.69169 12.1499 7.77502 11.2083 7.77502 10.0416C7.77502 8.84994 8.73336 7.8916 9.92502 7.8916C11.1167 7.8916 12.075 8.85827 12.075 10.0416C12.0667 11.2083 11.15 12.1583 10 12.1916Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.57498 14.8168C6.39998 15.6001 6.39998 16.8835 7.57498 17.6668C8.90831 18.5585 11.0916 18.5585 12.425 17.6668C13.6 16.8835 13.6 15.6001 12.425 14.8168C11.1 13.9335 8.90831 13.9335 7.57498 14.8168Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </figure>
            <p>Usuários</p>
          </div>
        </li>
        <li class="options" v-if="optionsListAlerts.length > 1">
          <div class="item" :class="{ 'active': $route.path === '/admin/create-alerts' || $route.path === '/admin/manage-alerts' || $route.path.includes('/admin/edit-alert'), opened: optionMobileListAlerts }" @click="openListOptionsMobile('alerts')">
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10 6.4585V10.8335" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17.5666 7.14987V12.8498C17.5666 13.7832 17.0666 14.6499 16.2583 15.1249L11.3083 17.9832C10.5 18.4499 9.49994 18.4499 8.68327 17.9832L3.73327 15.1249C2.92493 14.6582 2.42493 13.7915 2.42493 12.8498V7.14987C2.42493 6.21653 2.92493 5.34983 3.73327 4.87483L8.68327 2.0165C9.4916 1.54984 10.4916 1.54984 11.3083 2.0165L16.2583 4.87483C17.0666 5.34983 17.5666 6.2082 17.5666 7.14987Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 13.5V13.5833" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </figure>
            <p>Alertas</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path d="M4.58004 9.41016L11.1 15.9302C11.87 16.7002 13.13 16.7002 13.9 15.9302L20.42 9.41016" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="options">
            <p v-for="option in optionsListAlerts" :class="{ 'active' : $route.path === `/admin/${option.router}`}" @click="$router.push(`/admin/${option.router}`); closeAllOptionsMobile(); optionListMenu = !optionListMenu; checkMenuIsOpen();">{{ option.name }}</p>
          </div>
        </li>
        <li @click="$router.push({ name: 'admin.alerts' }); optionListMenu = false; checkMenuIsOpen();" v-else class="options">
          <div class="item" :class="{ 'active': $route.path === '/admin/alerts', opened: false }">
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10 6.4585V10.8335" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17.5666 7.14987V12.8498C17.5666 13.7832 17.0666 14.6499 16.2583 15.1249L11.3083 17.9832C10.5 18.4499 9.49994 18.4499 8.68327 17.9832L3.73327 15.1249C2.92493 14.6582 2.42493 13.7915 2.42493 12.8498V7.14987C2.42493 6.21653 2.92493 5.34983 3.73327 4.87483L8.68327 2.0165C9.4916 1.54984 10.4916 1.54984 11.3083 2.0165L16.2583 4.87483C17.0666 5.34983 17.5666 6.2082 17.5666 7.14987Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 13.5V13.5833" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </figure>
            <p>Alertas</p>
          </div>
        </li>
        <li @click="logout(); menuOpen = false; checkMenuIsOpen();">
          <div class="item">
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M14.5333 12.1832L16.6667 10.0498L14.5333 7.9165" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.1333 10.0498H16.6083" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.79997 16.6668C6.11663 16.6668 3.1333 14.1668 3.1333 10.0002C3.1333 5.8335 6.11663 3.3335 9.79997 3.3335" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </figure>
            <p>Sair</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path d="M4.58004 9.41016L11.1 15.9302C11.87 16.7002 13.13 16.7002 13.9 15.9302L20.42 9.41016" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </li>
      </ul>
      <button :class="{ 'active': $route.path === '/admin/home' || $route.path === '/admin/results' }" @click="$router.push('/admin/home'); menuOpen = false; optionListDocuments = false; checkMenuIsOpen();">
          <figure>
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 15.5V13" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.39172 2.85004L2.61672 7.47504C1.96672 7.9917 1.55006 9.08337 1.69172 9.90004L2.80006 16.5334C3.00006 17.7167 4.13339 18.675 5.33339 18.675H14.6667C15.8584 18.675 17.0001 17.7084 17.2001 16.5334L18.3084 9.90004C18.4417 9.08337 18.0251 7.9917 17.3834 7.47504L11.6084 2.85837C10.7167 2.1417 9.27506 2.1417 8.39172 2.85004Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </figure>
      </button>
      <button :class="{ 'active': $route.path === '/admin/create-documents' || $route.path === '/admin/manage-documents' || $route.path === '/admin/exceptions-documents' || $route.path.includes('/admin/view-document'), opened: optionListDocuments}" @click="optionListDocuments = !optionListDocuments; optionListMenu = false; checkMenuIsOpen();">
          <figure>
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="vuesax/linear/folder">
                      <g id="folder">
                          <path id="Vector" d="M18.3334 9.6665V14.6665C18.3334 17.9998 17.5001 18.8332 14.1667 18.8332H5.83341C2.50008 18.8332 1.66675 17.9998 1.66675 14.6665V6.33317C1.66675 2.99984 2.50008 2.1665 5.83341 2.1665H7.08341C8.33342 2.1665 8.60841 2.53317 9.08341 3.1665L10.3334 4.83317C10.6501 5.24984 10.8334 5.49984 11.6667 5.49984H14.1667C17.5001 5.49984 18.3334 6.33317 18.3334 9.6665Z" stroke-width="1.5" stroke-miterlimit="10"/>
                      </g>
                  </g>
              </svg>
          </figure>
      </button>
      <button @click="optionListMenu = !optionListMenu; optionListDocuments = false; checkMenuIsOpen(); closeAllOptionsMobile();">
        <figure>
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 6.3335H18" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M3 10.5H18" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M3 14.6665H18" stroke-width="1.5" stroke-linecap="round"/>
          </svg>
        </figure>
      </button>
      <button class="notify" @click="isNotificationsModalOpen = true; viewNotification(true)">
        <figure>
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.5206 3.41016C9.21058 3.41016 6.52058 6.10016 6.52058 9.41016V12.3002C6.52058 12.9102 6.26058 13.8402 5.95058 14.3602L4.80058 16.2702C4.09058 17.4502 4.58058 18.7602 5.88058 19.2002C10.1906 20.6402 14.8406 20.6402 19.1506 19.2002C20.3606 18.8002 20.8906 17.3702 20.2306 16.2702L19.0806 14.3602C18.7806 13.8402 18.5206 12.9102 18.5206 12.3002V9.41016C18.5206 6.11016 15.8206 3.41016 12.5206 3.41016Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
              <path d="M14.3699 3.69994C14.0599 3.60994 13.7399 3.53994 13.4099 3.49994C12.4499 3.37994 11.5299 3.44994 10.6699 3.69994C10.9599 2.95994 11.6799 2.43994 12.5199 2.43994C13.3599 2.43994 14.0799 2.95994 14.3699 3.69994Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.5195 19.5601C15.5195 21.2101 14.1695 22.5601 12.5195 22.5601C11.6995 22.5601 10.9395 22.2201 10.3995 21.6801C9.85953 21.1401 9.51953 20.3801 9.51953 19.5601" stroke-width="1.5" stroke-miterlimit="10"/>
              <circle v-if="newNotification" cx="20" cy="5" r="5" fill="#A30711" stroke='none'/>
          </svg>
        </figure>
      </button>
      <button :class="{ 'active': $route.path === '/admin/profile' }" @click="$router.push('/admin/profile'); menuOpen = false; optionListDocuments = false; checkMenuIsOpen();">
          <figure>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="vuesax/linear/profile-circle">
                      <g id="profile-circle">
                          <path id="Vector" d="M10.0999 10.6502C10.0416 10.6419 9.9666 10.6419 9.89994 10.6502C8.43327 10.6002 7.2666 9.40023 7.2666 7.92523C7.2666 6.41689 8.48327 5.19189 9.99993 5.19189C11.5083 5.19189 12.7333 6.41689 12.7333 7.92523C12.7249 9.40023 11.5666 10.6002 10.0999 10.6502Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path id="Vector_2" d="M15.6166 16.1498C14.1333 17.5081 12.1666 18.3331 9.99997 18.3331C7.8333 18.3331 5.86663 17.5081 4.3833 16.1498C4.46663 15.3665 4.96663 14.5998 5.8583 13.9998C8.14163 12.4831 11.875 12.4831 14.1416 13.9998C15.0333 14.5998 15.5333 15.3665 15.6166 16.1498Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path id="Vector_3" d="M10.0001 18.3332C14.6025 18.3332 18.3334 14.6022 18.3334 9.99984C18.3334 5.39746 14.6025 1.6665 10.0001 1.6665C5.39771 1.6665 1.66675 5.39746 1.66675 9.99984C1.66675 14.6022 5.39771 18.3332 10.0001 18.3332Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                  </g>
              </svg>
          </figure>
      </button>
  </nav>

  <Loader v-if="isLoggingOut" :zIndex="10" />
  <div class="blackdrop"  @click="menuOpenOrClose()"></div>
  <div class="blackdrop-mobile"  @click="optionListDocuments = false; optionListMenu = false; openListOptions(); checkMenuIsOpen();"></div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { isNotificationsModalOpen, newNotification, viewNotification} from '@/global/states/ModalState'
import { useAxios } from '@/api/axios'
import router from '@/router'
import { user } from '@/global/states/GlobalState'
import Loader from '@/components/loader/Loader.vue'

/* Defines whether the menu is open or not. */
const menuOpen = ref<boolean>(false)

const isLoggingOut = ref(false)

/* Open or close menu. */
const menuOpenOrClose = () => {
  menuOpen.value = !menuOpen.value
  optionListDocuments.value = false
  closeAllOptions()
  checkMenuIsOpen()
}

/* Open list menu options */
const openListOptionsMobile = (optionName: string) => {
  if (!menuOpen.value) {
    menuOpen.value = true
  }

  let latestValue = false

  switch (optionName) {
    case 'documents':
      latestValue = optionMobileListDocuments.value
      break
    case 'documents-subjects':
      latestValue = optionMobileListDocumentsSubjects.value
      break
    case 'sectors':
      latestValue = optionMobileListSectors.value
      break
    case 'users':
      latestValue = optionMobileListUsers.value
      break
    case 'models':
      latestValue = optionMobileListModels.value
      break
    case 'alerts':
      latestValue = optionMobileListAlerts.value
      break
    default:
      console.error(`Opção inválida: ${optionName}`)
      return
  }

  closeAllOptionsMobile()

  switch (optionName) {
    case 'documents':
      optionMobileListDocuments.value = !latestValue
      break
    case 'documents-subjects':
      optionMobileListDocumentsSubjects.value = !latestValue
      break
    case 'sectors':
      optionMobileListSectors.value = !latestValue
      break
    case 'users':
      optionMobileListUsers.value = !latestValue
      break
    case 'models':
      optionMobileListModels.value = !latestValue
      break
    case 'alerts':
      optionMobileListAlerts.value = !latestValue
      break
    default:
      console.error(`Opção inválida: ${optionName}`)
      break
  }

  checkMenuIsOpen()
}

/* Open list menu options */
const openListOptions = (optionName?: string) => {
  if (!menuOpen.value) {
    menuOpen.value = true
  }

  let latestValue = false

  switch (optionName) {
    case 'documents':
      latestValue = !optionListDocuments.value
      break
    case 'documents-subjects':
      latestValue = !optionListDocumentsSubjects.value
      break
    case 'sectors':
      latestValue = !optionListSectors.value
      break
    case 'users':
      latestValue = !optionListUsers.value
      break
    case 'models':
      latestValue = !optionListModels.value
      break
    case 'alerts':
      latestValue = !optionListAlerts.value
      break
    default:
      console.error(`Opção inválida: ${optionName}`)
      return
  }
  closeAllOptions()

  switch (optionName) {
    case 'documents':
      optionListDocuments.value = !latestValue
      break
    case 'documents-subjects':
      optionListDocumentsSubjects.value = !latestValue
      break
    case 'sectors':
      optionListSectors.value = !latestValue
      break
    case 'users':
      optionListUsers.value = !latestValue
      break
    case 'models':
      optionListModels.value = !latestValue
      break
    case 'alerts':
      optionListAlerts.value = !latestValue
      break
    default:
      console.error(`Opção inválida: ${optionName}`)
      break
  }

  checkMenuIsOpen()
}

/* Close all oprtion mobile */
const closeAllOptionsMobile = () => {
  optionMobileListDocuments.value = false
  optionMobileListDocumentsSubjects.value = false
  optionMobileListSectors.value = false
  optionMobileListUsers.value = false
  optionMobileListModels.value = false
  optionMobileListAlerts.value = false
}

/* Close all oprtion */
const closeAllOptions = () => {
  optionListDocuments.value = false
  optionListDocumentsSubjects.value = false
  optionListSectors.value = false
  optionListUsers.value = false
  optionListModels.value = false
  optionListAlerts.value = false
}

const optionListMenu = ref<boolean>(false)

/* Here are the item variables that have options mobile. */
const optionMobileListDocuments = ref<boolean>(false)
const optionMobileListDocumentsSubjects = ref<boolean>(false)
const optionMobileListSectors = ref<boolean>(false)
const optionMobileListUsers = ref<boolean>(false)
const optionMobileListModels = ref<boolean>(false)
const optionMobileListAlerts = ref<boolean>(false)

/* Here are the item variables that have options. */
const optionListDocuments = ref<boolean>(false)
const optionListDocumentsSubjects = ref<boolean>(false)
const optionListSectors = ref<boolean>(false)
const optionListUsers = ref<boolean>(false)
const optionListModels = ref<boolean>(false)
const optionListAlerts = ref<boolean>(false)

/* List menu options */
const optionsListDocuments = computed<{ name: string, router: string, show: boolean }[]>(() => [
  { name: 'Criar', router: 'create-documents', show: user.value.can!('create_documents') },
  { name: 'Gerenciar', router: 'manage-documents', show: user.value.can!('read_documents') }
].filter(o => o.show))

const optionsListDocumentSubjects = computed<{ name: string, path: string, show: boolean }[]>(() => [
  { name: 'Criar', path: 'create-document-subjects', show: user.value.can!('create_document_subjects') },
  { name: 'Gerenciar', path: 'manage-document-subjects', show: user.value.can!('read_all_document_subjects') }
].filter(o => o.show))

const optionsListSectors = computed<{ name: string; router: string, show: boolean}[]>(() => [
  { name: 'Criar', router: 'create-organizations', show: user.value.can!('create_organizations') },
  { name: 'Gerenciar', router: 'manage-organizations', show: user.value.can!('create_organizations') && user.value.can!('read_organizations') }
].filter(o => o.show))

const optionsListUsers = computed<{ name: string; router: string, show: boolean }[]>(() => [
  { name: 'Criar', router: 'create-users', show: user.value.can!('create_users') },
  { name: 'Gerenciar', router: 'manage-users', show: user.value.can!('create_users') && user.value.can!('read_users') }
].filter(o => o.show))

const optionsListModels = computed<{ name: string; router: string, show: boolean }[]>(() => [
  { name: 'Criar', router: 'create-models', show: user.value.can!('create_models') },
  { name: 'Gerenciar', router: 'manage-models', show: user.value.can!('read_models') && user.value.can!('create_models') }
].filter(o => o.show))

const optionsListAlerts = computed<{ name: string; router: string, show: boolean }[]>(() => [
  { name: 'Criar', router: 'create-alerts', show: user.value.can!('create_alerts') },
  { name: 'Gerenciar', router: 'manage-alerts', show: user.value.can!('read_alerts') }
].filter(o => o.show))

const axios = useAxios()

const logout = () => {
  isLoggingOut.value = true

  axios.post('/logout').then(() => {
    router.push({ name: 'login' })
  }).finally(() => isLoggingOut.value = false)
}

/* Assigns false to all options and assigns true to the selected options documents. */
const toggleOptionDocumentsSelected = (index: number) => {
  menuOpen.value = !menuOpen.value
  checkMenuIsOpen()
}

/* Check if the menu is open. */
const checkMenuIsOpen = () => {
  const screenWidth = window.innerWidth

  if (screenWidth > 425) {
    if (menuOpen.value) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  } else {
    if (optionListDocuments.value || optionListMenu.value) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }
}
</script>

<style lang="scss">
nav{
    &.desktop{
      width: 76px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      background: #FFF;
      position: fixed;
      z-index: 3;
      top: 0;
      left: 0;
      padding: 30px 5px 14px;
      row-gap: 5px;
      overflow-y: auto;
      box-sizing: border-box;
      transition: width .2s;
      &::-webkit-scrollbar{
          width: 0;
      }
      img{
          width: 35px;
          margin: 0 auto 15px 16px;
          cursor: pointer;
      }
      div{
          margin: auto 0;
      }
      button{
          padding: 0 10px;
          width: calc(100% - 26px);
          height: 40px;
          min-height: 40px;
          display: flex;
          align-items: center;
          justify-content: start;
          background: transparent;
          border: none;
          border-radius: 50%;
          transition: background .2s;
          margin: 13px 13px 14px;
          figure{
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              transition: background .2s;
              svg{
                  width: 20px;
                  min-width: 20px;
                  height: 20px;
                  min-height: 20px;
                  stroke: #090909;
                  transition: stroke .2s;
                  cursor: pointer;
                  &.alerts{
                    width: 24px;
                    min-width: 24px;
                    height: 24px;
                    min-height: 24px;
                  }
              }
          }
          &.active{
              background: $fluxoo-primary-color !important;
              cursor: default !important;
              &::before{
                  color: #FFF !important;
              }
              svg{
                  stroke: #FFF !important;
              }
          }
          &.options{
              position: relative;
              &.active{
                  cursor: pointer !important;
                  &.opened{
                      background: $fluxoo-primary-color !important;
                  }
                  &::after{
                      border-color: #FFF !important;
                  }
              }
              &::after{
                  content: '';
                  opacity: 0;
                  width: 10px;
                  height: 10px;
                  border-left: 2px solid #090909;
                  border-top: 2px solid #090909;
                  position: absolute;
                  top: 24px;
                  right: 25px;
                  transform: rotate(225deg);
                  border-radius: 2px;
                  transition: border-color .2s, transform .2s, top .2s;
              }
              &:hover{
                  &::after{
                      border-color: $fluxoo-primary-color;
                  }
              }
              &.opened{
                  background: $fluxoo-menu-primary-color-hover !important;
                  &::after{
                      transform: rotate(45deg);
                      top: 30px;
                      border-color: $fluxoo-primary-color;
                  }
                  &::before{
                      color: $fluxoo-primary-color;
                  }
                  figure{
                      svg{
                          stroke: $fluxoo-primary-color;
                      }
                  }
              }
              & ~ ul{
                  display: none;
              }
          }
          &:hover{
              background: $fluxoo-menu-primary-color-hover;
              &::before{
                  color: $fluxoo-primary-color;
              }
              svg{
                  stroke: $fluxoo-primary-color;
              }
          }
          &::before{
              content: '';
              color: #090909;
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              position: absolute;
              left: -100%;
              opacity: 0;
              transition: left .2s ease-out, color .2s;
          }
          &.menu{
              figure{
                  svg{
                      path{
                          transition: all 0.2s;
                      }
                  }
              }
              &:hover{
                  background: unset;
                  svg{
                  stroke: $fluxoo-primary-color;
                  }
              }
              &::before{
                  content: '';
              }
          }
          &#home-menu{
            &::before{
              content: 'Home';
            }
          }
          &#documents-menu{
            &::before{
              content: 'Documentos';
            }
          }
          &#document-subjects-menu{
            &::before{
              content: 'Assuntos';
            }
          }
          &#models-menu{
            &::before{
              content: 'Modelos';
            }
          }
          &#sectors-menu{
            &::before{
              content: 'Setores';
            }
          }
          &#users-menu{
            &::before{
              content: 'Usuários';
            }
          }
          &#help{
            &::before{
              content: 'Ajuda';
            }
          }
          &#alerts-menu{
            &::before{
              content: 'Alertas';
            }
          }
          &#profile-menu{
            &::before{
              content: 'Perfil';
            }
          }
          &#logout-menu{
            &::before{
              content: 'Sair';
            }
          }
          &#alerts-menu-normal{
            padding: 0 0 0 10px;
            figure{
              svg{
                width: 23px;
                min-width: 23px;
                height: 23px;
                min-height: 23px;
              }
            }
            &::before{
              content: 'Alertas';
            }
          }
      }
      ul{
          display: none;
      }
      &.open{
          width: 230px;
          padding: 30px 5px 14px;
          align-items: start;
          button{
              width: calc(100% - 13px);
              justify-content: start;
              box-sizing: content-box;
              border-radius: 30px;
              height: 66px;
              min-height: 66px;
              margin: 0;
              padding: 0 0 0 13px;
              outline: none;
              margin: 0 0 1px 0;
              position: relative;
              &::before{
                opacity: 1;
                left: 60px;
                animation: enterContent ease-in-out .6s;
              }
              figure{
                  width: 100%;
                  max-width: 40px;
                  height: 100%;
                  max-height: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: #FFF;
              }
              &.active{
                  figure{
                      svg{
                          stroke: $fluxoo-primary-color !important;
                      }
                  }
              }
              &.options{
                  &::after{
                      opacity: 1;
                      animation: enterContent ease-in .6s;
                  }
              }
              &:hover{
                  &:before{
                      color: $fluxoo-primary-color;
                  }
              }
              &.menu{
                  figure{
                      svg{
                          path{
                              &:nth-child(1){
                                  transform: rotate(45deg) translate(2px, -6px);
                              }
                              &:nth-child(2){
                                  opacity: 0;
                              }
                              &:nth-child(3){
                                  transform: rotate(-45deg) translate(-10px, -2px);
                              }
                          }
                      }
                  }
              }
              &#alerts-menu-normal{
                padding: 0 0 0 13px;
                figure{
                  svg{
                    margin: 0 0 0 2px;
                  }
                }
              }
          }
          ul{
            display: flex;
            flex-direction: column;
            align-items: start;
            row-gap: 10px;
            margin: 0;
            animation: leaveContentOptionsMenu ease-in-out .2s;
            li{
                height: 43px;
                width: 170px;
                border-radius: 30px;
                background: #F5F5F5;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 13px 22px 13px 16px;
                box-sizing: border-box;
                transition: background .2s;
                cursor: pointer;
                p{
                    color: #6F6F6F;
                    font-family: 'Inter', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin: 0;
                    transition: color .2s;
                }
                span{
                    width: 10px;
                    height: 10px;
                    border-left: 2px solid #6F6F6F;
                    border-top: 2px solid #6F6F6F;
                    transform: rotate(135deg);
                    border-radius: 2px;
                    transition: border-color .2s;
                }
                &:hover{
                    background: #F5F5F5;
                    p{
                        color: $fluxoo-primary-color;
                    }
                    span{
                        border-color: $fluxoo-primary-color;
                    }
                }
                &.selected{
                    cursor:  default !important;
                    background: $fluxoo-menu-primary-color-hover;
                    p{
                        color: $fluxoo-primary-color;
                    }
                    span{
                        border-color: $fluxoo-primary-color;
                    }
                }
            }
            &.opened{
              margin: 9px 0 10px;
              display: flex;
              animation: enterContentOptionsMenu ease-in-out .2s;
            }
          }
          & ~ .blackdrop{
              display: flex;
              opacity: 1;
              animation: enterContent ease-in .2s;
          }
      }
    }
    &.mobile{
      display: none;
    }
}

.blackdrop{
    width: 100dvw;
    height: 100dvh;
    background: #00000060;
    display: none;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    opacity: 0;
}

.blackdrop-mobile{
    width: 100dvw;
    height: 100dvh;
    background: #00000060;
    display: none !important;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    opacity: 0;
}

@media(max-width: 425px){
    nav{
        &.desktop{
            display: none;
        }
        &.mobile{
            display: flex;
            width: 100%;
            height: 80px;
            position: fixed;
            bottom: 0;
            left: 0;
            padding: 0 15px;
            border-radius: 16px 16px 0px 0px;
            background: #FFF;
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            z-index: 3;
            button{
                width: 40px;
                min-width: 40px;
                height: 40px;
                border-radius: 50%;
                transition: background .2s;
                cursor: pointer;
                figure{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0;
                    svg{
                        width: 20px;
                        min-width: 20px;
                        height: 20px;
                        min-height: 20px;
                        stroke: #090909;
                        transition: stroke .2s;
                        path{
                          transition: transform .2s;
                        }
                    }
                }
                &.notify{
                    svg{
                        width: 22px;
                        min-width: 22px;
                        height: 22px;
                        min-height: 22px;
                        stroke: #090909;
                    }
                }
                &.active{
                    background: $fluxoo-primary-color !important;
                    svg{
                        stroke: #FFF !important;
                    }
                }
                &.opened{
                    background: $fluxoo-menu-primary-color-hover !important;
                    svg{
                        stroke: $fluxoo-primary-color !important;
                    }
                    &.active{
                        background: $fluxoo-primary-color !important;
                        svg{
                            stroke: #FFF !important;
                        }
                    }
                }
                &:hover{
                    background: $fluxoo-menu-primary-color-hover;
                    svg{
                        stroke: $fluxoo-primary-color;
                    }
                }
            }
            ul{
                width: 100%;
                display: flex;
                flex-direction: column;
                border-radius: 16px 16px 0px 0px;
                background: #FFF;
                box-shadow: -5px -28px 63px -49px rgba(0, 0, 0, 0.5);
                position: fixed;
                bottom: -100%;
                opacity: 0;
                left: 0;
                margin: 0;
                padding: 20px 0 30px;
                z-index: -1;
                transition: bottom .6s ease-out, opacity .9s;
                &.options-mobile{
                  bottom: -110%;
                  max-height: 90vh;
                  z-index: 1;
                  overflow-y: auto;
                  transition: bottom .6s ease-out, opacity .9s;
                  h1{
                    width: 100%;
                    color: #090909;
                    font-family: 'Inter', sans-serif;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-align: center;
                    margin: 0 0 40px 0;
                  }
                  li{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    .item{
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      column-gap: 10px;
                      &:hover{
                        figure{
                          background: $fluxoo-menu-primary-color-hover;
                          svg{
                            stroke: $fluxoo-primary-color;
                            transform: unset !important;
                          }
                        }
                        p{
                          color: $fluxoo-primary-color;
                        }
                        svg{
                          stroke: $fluxoo-primary-color;
                        }
                      }
                      &.active{
                        figure{
                          background: $fluxoo-primary-color !important;
                          svg{
                            stroke: #FFF  !important;
                            transform: unset !important;
                          }
                        }
                        p{
                          color: $fluxoo-primary-color !important;
                        }
                        svg{
                          stroke: $fluxoo-primary-color !important;
                        }
                      }
                      &.opened{
                        figure{
                          background: $fluxoo-menu-primary-color-hover;
                          svg{
                            stroke: $fluxoo-primary-color;
                            transform: unset !important;
                          }
                        }
                        p{
                          color: $fluxoo-primary-color;
                        }
                        svg{
                          stroke: $fluxoo-primary-color;
                          transform: rotate(180deg);
                        }
                        & ~ .options{
                          display: flex;
                          height: 48px;
                          margin: 5px 0 0 50px;
                          animation: enterContent ease-in .2s;
                        }
                      }
                    }
                    .options{
                      height: 0;
                      display: none;
                      flex-direction: column;
                      align-items: start;
                      margin: 0 0 0 50px;
                      row-gap: 14px;
                      transition: height .2s, margin-top .2s;
                      p{
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        transition: color .2s;
                        &:hover{
                          color: $fluxoo-primary-color;
                        }
                        &.active{
                          color: $fluxoo-primary-color;
                        }
                      }
                    }
                    &:last-child{
                      margin: 20px 0 60px 0;
                    }
                    &.options{
                      svg{
                        display: flex;
                      }
                    }
                    figure{
                      width: 40px;
                      min-width: 40px;
                      height: 40px;
                      min-height: 40px;
                      border-radius: 50%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin: 0;
                      background: transparent;
                      transition: background .2s;
                      svg{
                        display: flex;
                        width: 20px;
                        min-width: 20px;
                        height: 20px;
                        min-height: 20px;
                        stroke: #090909;
                        transition: stroke .2s;
                      }
                    }
                    p{
                      width: 100%;
                      color: #090909;
                      font-family: 'Inter', sans-serif;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: normal;
                      margin: 0;
                      transition: color .2s;
                    }
                    svg{
                      width: 24px;
                      min-width: 24px;
                      height: 24px;
                      min-height: 24px;
                      stroke: #090909;
                      transition: stroke .2s, transform .2s;
                      display: none;
                    }
                    div{
                      display: flex;
                      flex-direction: column;
                      align-items: start;
                    }
                  }
                }
                li{
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 15px;
                    margin: 20px 0 0 0;
                    p{
                        color: #090909;
                        font-family: 'Inter', sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        margin: 0;
                        transition: color .2s;
                    }
                    span{
                        width: 10px;
                        height: 10px;
                        border-left: 2px solid #090909;
                        border-top: 2px solid #090909;
                        transform: rotate(135deg);
                        border-radius: 2px;
                        transition: border-color .2s;
                    }
                    &.selected{
                        p{
                            color: $fluxoo-primary-color;
                        }
                        span{
                            border-color: $fluxoo-primary-color;
                        }
                    }
                    &:first-child{
                        margin: 0 0 18px;
                        h1{
                            color: #090909;
                            font-family: 'Inter', sans-serif;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            margin: 0;
                        }
                        svg{
                            width: 24px;
                            min-width: 24px;
                            height: 24px;
                            min-height: 24px;
                            stroke: #090909;
                            transition: stroke .2s;
                            cursor: pointer;
                            &:hover{
                                stroke: $fluxoo-primary-color;
                            }
                        }
                    }
                }
            }
            &.opened{
              &.documents{
                .options-documents{
                  bottom: 62px;
                  opacity: 1;
                  &.options-mobile{
                    bottom: 0;
                  }
                }
              }
              &.menu{
                button{
                  &:nth-child(5){
                    figure{
                      width: 40px;
                      height: 40px;
                      position: absolute;
                      z-index: 10;
                      left: 0;
                      right: 0;
                      bottom: 19px;
                      margin: 0 auto;
                      background: $fluxoo-primary-color;
                      border-radius: 50%;
                      svg{
                        stroke: #FFF !important;
                        transform: translateY(2px) translateX(1px);
                        path{
                          &:nth-child(1){
                              transform: rotate(45deg) translate(2px, -7px);
                          }
                          &:nth-child(2){
                              opacity: 0;
                          }
                          &:nth-child(3){
                              transform: rotate(-45deg) translate(-10px, -2px);
                          }
                        }
                      }
                    }
                  }
                }
                .options-mobile{
                  bottom: 0px;
                  opacity: 1;
                  &.options-mobile{
                    bottom: 0;
                  }
                }
              }
              & ~ .blackdrop-mobile{
                display: flex !important;
                opacity: 1;
                animation: enterContent ease-in .2s;
              }
            }
        }
    }

    .blackdrop{
        display: none !important;
    }
}
</style>
