<template>
  <div class="range">
    <p id="range1" ref="resultRange1">{{ sliderOneValue }}</p>
    <p id="range2" ref="resultRange2">{{ sliderTwoValue }}</p>
    <div class="container">
      <div class="slider-track" ref="sliderTrack"></div>
      <input
        type="range"
        min="0"
        max="31"
        v-model.number="sliderOneValue"
        @input="updateParentValues"
        ref="sliderOne"
      >
      <input
        type="range"
        min="0"
        max="31"
        v-model.number="sliderTwoValue"
        @input="updateParentValues"
        ref="sliderTwo"
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, defineProps } from 'vue'

const props = defineProps<{
  startValue?: number;
  endValue?: number;
  updateValues:(value1: number, value2: number) => void;
}>()

const sliderOneValue = ref(props.startValue ?? 0)
const sliderTwoValue = ref(props.endValue ?? 0)
const minGap = 0
const sliderTrack = ref<HTMLDivElement | null>(null)
const resultRange1 = ref<HTMLDivElement | null>(null)
const resultRange2 = ref<HTMLDivElement | null>(null)
const sliderMaxValue = 31

const getCompensation = (value: number): number => {
  switch (value) {
    case 0:
    case 1:
    case 2:
      return 11
    case 3:
      return 10
    case 4:
      return 9
    case 5:
      return 8
    case 6:
    case 7:
      return 7
    case 8:
      return 6
    case 9:
    case 10:
      return 5
    case 11:
      return 4
    case 12:
    case 13:
      return 3
    case 14:
      return 2
    case 15:
      return 1
    case 16:
    case 17:
      return 0
    case 18:
      return -1
    case 19:
      return -2
    case 20:
      return -3
    case 21:
      return -3
    case 22:
      return -4
    case 23:
    case 24:
      return -5
    case 25:
      return -6
    case 26:
    case 27:
      return -7
    case 28:
      return -8
    case 29:
      return -9
    case 30:
    case 31:
      return -10
    default:
      return 0
  }
}

const displayValues = () => {
  if (sliderTrack.value) {
    const percent1 = (sliderOneValue.value / sliderMaxValue) * 100
    const percent2 = (sliderTwoValue.value / sliderMaxValue) * 100
    sliderTrack.value.style.background = `linear-gradient(to right, #F1F1F1 ${percent1}%, #079F72 ${percent1}%, #079F72 ${percent2}%, #F1F1F1 ${percent2}%)`

    if (resultRange1.value && resultRange2.value) {
      const leftOffset1 = getCompensation(sliderOneValue.value)
      const leftOffset2 = getCompensation(sliderTwoValue.value)

      resultRange1.value.style.left = `calc(${percent1}% - ${resultRange1.value.offsetWidth / 2}px + ${leftOffset1}px)`
      resultRange2.value.style.left = `calc(${percent2}% - ${resultRange2.value.offsetWidth / 2}px + ${leftOffset2}px)`
    }
  }
}

const ensureRangeValidity = () => {
  if (sliderOneValue.value > sliderTwoValue.value) {
    sliderOneValue.value = sliderTwoValue.value
  } else if (sliderTwoValue.value < sliderOneValue.value) {
    sliderTwoValue.value = sliderOneValue.value
  }
}

const updateParentValues = () => {
  if(sliderOneValue.value == 0 && sliderTwoValue.value != 0){
    sliderOneValue.value = 1;
  }
  ensureRangeValidity()
  props.updateValues(sliderOneValue.value, sliderTwoValue.value)
  displayValues()
}

watch(sliderOneValue, updateParentValues)
watch(sliderTwoValue, updateParentValues)

onMounted(() => {
  displayValues()
})
</script>

<style lang="scss" scoped>

.range {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  .container {
    position: relative;
    width: 100%;
    height: 50px;
    input[type="range"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      outline: none;
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      background-color: transparent;
      pointer-events: none;
      &::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        height: 5px;
      }
      &::-moz-range-track {
        -moz-appearance: none;
        height: 5px;
      }
      &::-ms-track {
        appearance: none;
        height: 5px;
      }
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 1.7em;
        width: 1.7em;
        background-color: $fluxoo-primary-color-hover;
        cursor: grab;
        margin-top: -9px;
        pointer-events: auto;
        border-radius: 50%;
        &:active {
          background-color: $fluxoo-primary-color-hover;
          border: 1px solid $fluxoo-primary-color-hover;
        }
      }
      &::-moz-range-thumb {
        -webkit-appearance: none;
        height: 1.7em;
        width: 1.7em;
        cursor: pointer;
        border-radius: 50%;
        background-color: $fluxoo-primary-color;
        pointer-events: auto;
        border: none;
      }
      &::-ms-thumb {
        appearance: none;
        height: 1.7em;
        width: 1.7em;
        cursor: pointer;
        border-radius: 50%;
        background-color: $fluxoo-primary-color;
        pointer-events: auto;
      }
    }
  }
  .slider-track {
    width: 100%;
    height: 12px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    border-radius: 5px;
  }
  p{
    width: 27px;
    text-align: center;
    color: #090909;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    position: absolute;
    top: 38px;
  }
}
</style>
