<template>
  <div class="manage-users">
    <label @click="$router.push('/admin/home')" class="back">
      <figure>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M9.57 5.93018L3.5 12.0002L9.57 18.0702M20.5 12.0002H3.67" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
      </figure>
      <p>Gerenciar usuários</p>
    </label>
    <div class="header">
      <label class="search" for="search-user">
        <input autocomplete='off' type="text" placeholder="Digite sua busca" id="search-user" name="search-user" v-model="filter.search" @keydown.enter.prevent="getUsers">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M17.5002 17.5002L13.881 13.881M13.881 13.881C14.5001 13.2619 14.9912 12.527 15.3262 11.7181C15.6612 10.9093 15.8337 10.0423 15.8337 9.16684C15.8337 8.29134 15.6612 7.42441 15.3262 6.61555C14.9912 5.80669 14.5001 5.07174 13.881 4.45267C13.2619 3.8336 12.527 3.34252 11.7181 3.00748C10.9093 2.67244 10.0423 2.5 9.16684 2.5C8.29134 2.5 7.42441 2.67244 6.61555 3.00748C5.80669 3.34252 5.07174 3.8336 4.45267 4.45267C3.2024 5.70295 2.5 7.39868 2.5 9.16684C2.5 10.935 3.2024 12.6307 4.45267 13.881C5.70295 15.1313 7.39868 15.8337 9.16684 15.8337C10.935 15.8337 12.6307 15.1313 13.881 13.881Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </label>
      <div class="filters">
        <button class="tooltip"  @click="$router.push('/admin/create-users')">
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.0399 3.01976L8.15988 10.8998C7.85988 11.1998 7.55988 11.7898 7.49988 12.2198L7.06988 15.2298C6.90988 16.3198 7.67988 17.0798 8.76988 16.9298L11.7799 16.4998C12.1999 16.4398 12.7899 16.1398 13.0999 15.8398L20.9799 7.95976C22.3399 6.59976 22.9799 5.01976 20.9799 3.01976C18.9799 1.01976 17.3999 1.65976 16.0399 3.01976Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M14.9102 4.1499C15.5802 6.5399 17.4502 8.4099 19.8502 9.0899" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </figure>
        </button>
      </div>
    </div>
    <div v-if="allowedUsers && allowedUsers.length" class="users">
      <div class="users-title doc-grid">
        <label for="all-users" class="check">
          <input type="checkbox" name="all-users" id="all-users" :checked="selectedUsers.length == allowedUsers.length" @change="($event.target as HTMLInputElement).checked ? selectedUsers = allowedUsers : selectedUsers = []">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
              <path d="M5.06761 9.95167C4.94632 10.0727 4.78194 10.1407 4.61057 10.1407C4.43919 10.1407 4.27482 10.0727 4.15352 9.95167L0.501349 6.2995C0.411146 6.20946 0.339583 6.10251 0.290756 5.98479C0.241929 5.86706 0.216797 5.74086 0.216797 5.61341C0.216797 5.48596 0.241929 5.35976 0.290756 5.24203C0.339583 5.1243 0.411146 5.01736 0.501349 4.92732L0.958393 4.47132C1.14038 4.2894 1.38716 4.1872 1.64448 4.1872C1.9018 4.1872 2.14858 4.2894 2.33057 4.47132L4.61057 6.74923L10.7671 0.592713C10.9491 0.410791 11.1959 0.308594 11.4532 0.308594C11.7105 0.308594 11.9573 0.410791 12.1393 0.592713L12.5963 1.04976C12.6865 1.1398 12.7581 1.24674 12.8069 1.36447C12.8557 1.48219 12.8809 1.60839 12.8809 1.73584C12.8809 1.86329 12.8557 1.98949 12.8069 2.10722C12.7581 2.22495 12.6865 2.33189 12.5963 2.42193L5.06761 9.95167Z" fill="white"/>
            </svg>
          </span>
        </label>
        <label>
          <h1>Usuários</h1>
        </label>
        <label>
          <h1>Nível e E-mail</h1>
        </label>
        <label>
          <h1>Status</h1>
        </label>
        <label>
          <h1>Ações</h1>
        </label>
      </div>
      <div class="users-full" v-if="selectedUsers.length">
        <svg @click="selectedUsers = []" class="close" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M18 6L6 18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6 6L18 18"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <h1>Ações em massa</h1>
        <h2><strong>{{ selectedUsers.length }} itens foram selecionados</strong>, oque deseja fazer com todos eles?</h2>
        <div class="actions">
          <div @click="deleteUser(selectedUsers.map((user) => user.id!))">
            <p>excluir todos</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill='none' d="M21 5.98C17.67 5.65 14.32 5.48 10.98 5.48C9 5.48 7.02 5.58 5.04 5.78L3 5.98M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97M18.85 9.14L18.2 19.21C18.09 20.78 18 22 15.21 22H8.79C6 22 5.91 20.78 5.8 19.21L5.15 9.14M10.33 16.5H13.66M9.5 12.5H14.5" stroke="#292F31" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div @click="userToToggleStatus = undefined; isConfirmStatusModalOpen = true">
            <p>desativar todos</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M18.41 18.09L15.59 20.91" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M18.41 20.91L15.59 18.09" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.45006 10.79 7.56006 8.84 7.56006 6.44C7.56006 3.99 9.54006 2 12.0001 2C14.4501 2 16.4401 3.99 16.4401 6.44C16.4301 8.84 14.5401 10.79 12.1601 10.87Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 21.81C10.18 21.81 8.37005 21.35 6.99005 20.43C4.57005 18.81 4.57005 16.17 6.99005 14.56C9.74005 12.72 14.25 12.72 17 14.56" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div @click="updateUserStatus(selectedUsers.map((user) => user.id!), '1')">
            <p>ativar todos</p>
            <svg xmlns="http://www.w3.org/2000/svg" style="width: 13px" viewBox="0 0 13 10" fill="none">
              <g clip-path="url(#clip0_366_3671)">
                <path d="M4.6487 9.24519C4.53246 9.36121 4.37493 9.42638 4.2107 9.42638C4.04646 9.42638 3.88894 9.36121 3.7727 9.24519L0.272696 5.74519C0.186251 5.6589 0.11767 5.55641 0.0708778 5.44359C0.0240854 5.33077 0 5.20983 0 5.08769C0 4.96555 0.0240854 4.84461 0.0708778 4.73178C0.11767 4.61896 0.186251 4.51648 0.272696 4.43019L0.710696 3.99319C0.885096 3.81885 1.1216 3.72091 1.3682 3.72091C1.61479 3.72091 1.8513 3.81885 2.0257 3.99319L4.2107 6.17619L10.1107 0.276188C10.2851 0.101846 10.5216 0.00390625 10.7682 0.00390625C11.0148 0.00390625 11.2513 0.101846 11.4257 0.276188L11.8637 0.714188C11.9501 0.800475 12.0187 0.902962 12.0655 1.01578C12.1123 1.12861 12.1364 1.24955 12.1364 1.37169C12.1364 1.49383 12.1123 1.61477 12.0655 1.72759C12.0187 1.84041 11.9501 1.9429 11.8637 2.02919L4.6487 9.24519Z" stroke='none'/>
              </g>
              <defs>
                <clipPath id="clip0_366_3671">
                  <rect width="12.141" height="9.427" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <div class="users-list doc-grid" v-for="user in allowedUsers">
        <label :for="`user-${user.id}`" class="check">
          <input type="checkbox" name="users" :id="`user-${user.id}`" :checked="selectedUsers.find((u) => u.id == user.id) ? true : false" @change="selectedUsers.find((u) => u.id == user.id) ? (selectedUsers = selectedUsers.filter((d) => user != d)) : selectedUsers.push(user)">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
              <path d="M5.06761 9.95167C4.94632 10.0727 4.78194 10.1407 4.61057 10.1407C4.43919 10.1407 4.27482 10.0727 4.15352 9.95167L0.501349 6.2995C0.411146 6.20946 0.339583 6.10251 0.290756 5.98479C0.241929 5.86706 0.216797 5.74086 0.216797 5.61341C0.216797 5.48596 0.241929 5.35976 0.290756 5.24203C0.339583 5.1243 0.411146 5.01736 0.501349 4.92732L0.958393 4.47132C1.14038 4.2894 1.38716 4.1872 1.64448 4.1872C1.9018 4.1872 2.14858 4.2894 2.33057 4.47132L4.61057 6.74923L10.7671 0.592713C10.9491 0.410791 11.1959 0.308594 11.4532 0.308594C11.7105 0.308594 11.9573 0.410791 12.1393 0.592713L12.5963 1.04976C12.6865 1.1398 12.7581 1.24674 12.8069 1.36447C12.8557 1.48219 12.8809 1.60839 12.8809 1.73584C12.8809 1.86329 12.8557 1.98949 12.8069 2.10722C12.7581 2.22495 12.6865 2.33189 12.5963 2.42193L5.06761 9.95167Z" fill="white"/>
            </svg>
          </span>
        </label>
        <label class="title">
          <h1>{{ `${user.name} ${user.last_name ?? ''}` }}</h1>
          <h2>{{ user.organization?.acronym ?? 'Sem organização' }}</h2>
        </label>
        <label>
          <h1>{{ user.roles[0].name }}</h1>
          <h2>{{ user.email }}</h2>
        </label>
        <label class="stats">
          <input type="checkbox" v-model="user.status" @change.prevent="handleUserToggleStatus($event, user.id!); user.status = true">
          <span></span>
        </label>
        <label for="" class="actions">
          <button class="tooltip" @click="$router.push({ name: 'admin.users.edit', params: { id: user.id } })">
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill='none' d="M13.2594 3.59924L5.04936 12.2892C4.73936 12.6192 4.43936 13.2692 4.37936 13.7192L4.00936 16.9592C3.87936 18.1292 4.71936 18.9292 5.87936 18.7292L9.09936 18.1792C9.54936 18.0992 10.1794 17.7692 10.4894 17.4292L18.6994 8.73924C20.1194 7.23924 20.7594 5.52924 18.5494 3.43924C16.3494 1.36924 14.6794 2.09924 13.2594 3.59924Z"  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.89 5.05078C12.0996 6.39164 12.7486 7.62492 13.735 8.55703C14.7214 9.48914 15.9894 10.0673 17.34 10.2008M3 22.0008H21"  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </figure>
          </button>
          <button v-if="user.id != authenticatedUser.id" class="tooltip" @click="isConfirmDeleteModal = true; userToDeleteId = user.id!">
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill='none' d="M21 5.98C17.67 5.65 14.32 5.48 10.98 5.48C9 5.48 7.02 5.58 5.04 5.78L3 5.98M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97M18.85 9.14L18.2 19.21C18.09 20.78 18 22 15.21 22H8.79C6 22 5.91 20.78 5.8 19.21L5.15 9.14M10.33 16.5H13.66M9.5 12.5H14.5" stroke="#292F31" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </figure>
          </button>
        </label>
      </div>
    </div>
    <div class="no-options" v-else-if="allowedUsers && !allowedUsers?.length">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path fill='#171717' d="M14.9999 5.96651C14.9499 5.95817 14.8916 5.95817 14.8416 5.96651C13.6916 5.92484 12.7749 4.98317 12.7749 3.81651C12.7749 2.62484 13.7332 1.6665 14.9249 1.6665C16.1166 1.6665 17.0749 2.63317 17.0749 3.81651C17.0666 4.98317 16.1499 5.92484 14.9999 5.96651Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        <path stroke='#171717' d="M14.1415 12.0333C15.2831 12.225 16.5415 12.025 17.4248 11.4333C18.5998 10.65 18.5998 9.36664 17.4248 8.58331C16.5331 7.99164 15.2581 7.79163 14.1165 7.99163" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        <path fill='#171717' d="M4.9749 5.96651C5.0249 5.95817 5.08324 5.95817 5.13324 5.96651C6.28324 5.92484 7.1999 4.98317 7.1999 3.81651C7.1999 2.62484 6.24157 1.6665 5.0499 1.6665C3.85824 1.6665 2.8999 2.63317 2.8999 3.81651C2.90824 4.98317 3.8249 5.92484 4.9749 5.96651Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        <path stroke='#171717' d="M5.83328 12.0333C4.69162 12.225 3.43328 12.025 2.54995 11.4333C1.37495 10.65 1.37495 9.36664 2.54995 8.58331C3.44162 7.99164 4.71662 7.79163 5.85828 7.99163" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        <path fill='#171717' d="M10.0001 12.1916C9.95015 12.1833 9.89181 12.1833 9.84181 12.1916C8.69181 12.1499 7.77515 11.2083 7.77515 10.0416C7.77515 8.84994 8.73348 7.8916 9.92514 7.8916C11.1168 7.8916 12.0751 8.85827 12.0751 10.0416C12.0668 11.2083 11.1501 12.1583 10.0001 12.1916Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        <path stroke='#171717' d="M7.5751 14.8168C6.4001 15.6001 6.4001 16.8835 7.5751 17.6668C8.90843 18.5585 11.0918 18.5585 12.4251 17.6668C13.6001 16.8835 13.6001 15.6001 12.4251 14.8168C11.1001 13.9335 8.90843 13.9335 7.5751 14.8168Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
      </svg>
      <h1>Sem usuários para gerenciar no momento</h1>
      <p><a @click="$router.push('/admin/create-users')">Clique aqui</a> para cadastrar</p>
    </div>
    <SpinnerLoader v-else />
    <nav :class="{active: isMobileOptionsFilterOpen}" @click="toggleOverflow" class="action-filters">
      <button @click.stop @click="toggleOverflow(); $router.push('/admin/create-users');">
        <figure>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M9.16602 1.6665H7.49935C3.33268 1.6665 1.66602 3.33317 1.66602 7.49984V12.4998C1.66602 16.6665 3.33268 18.3332 7.49935 18.3332H12.4993C16.666 18.3332 18.3327 16.6665 18.3327 12.4998V10.8332" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.3675 2.51688L6.80088 9.08354C6.55088 9.33354 6.30088 9.82521 6.25088 10.1835L5.89254 12.6919C5.75921 13.6002 6.40088 14.2335 7.30921 14.1085L9.81754 13.7502C10.1675 13.7002 10.6592 13.4502 10.9175 13.2002L17.4842 6.63354C18.6175 5.50021 19.1509 4.18354 17.4842 2.51688C15.8175 0.850211 14.5009 1.38354 13.3675 2.51688Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.4258 3.4585C12.9841 5.45016 14.5424 7.0085 16.5424 7.57516" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </figure>
      </button>
      <button>
        <figure>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M7 7L17 17M7 17L17 7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </figure>
      </button>
    </nav>
    <MyModal :width="'330px !important'" :height="'260px !important'" :isOpen="isConfirmDeleteModal" :class-name="'confirm-delete'" :toggleModal="(v: boolean) => isConfirmDeleteModal = v" :zIndex="100">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
          <path d="M42.2722 12.6747L29.4021 5.24301C27.3005 4.02967 24.7005 4.02967 22.5771 5.24301L9.72881 12.6747C7.62715 13.888 6.32715 16.1413 6.32715 18.5897V33.4097C6.32715 35.8363 7.62715 38.0897 9.72881 39.3247L22.5988 46.7563C24.7005 47.9697 27.3005 47.9697 29.4238 46.7563L42.2938 39.3247C44.3955 38.1113 45.6955 35.858 45.6955 33.4097V18.5897C45.6738 16.1413 44.3738 13.9097 42.2722 12.6747ZM24.3755 16.7913C24.3755 15.903 25.1121 15.1663 26.0005 15.1663C26.8888 15.1663 27.6255 15.903 27.6255 16.7913V28.1663C27.6255 29.0547 26.8888 29.7913 26.0005 29.7913C25.1121 29.7913 24.3755 29.0547 24.3755 28.1663V16.7913ZM27.9938 36.0313C27.8855 36.2913 27.7338 36.5297 27.5388 36.7463C27.1271 37.158 26.5855 37.3747 26.0005 37.3747C25.7188 37.3747 25.4371 37.3097 25.1771 37.2013C24.8955 37.093 24.6788 36.9413 24.4621 36.7463C24.2671 36.5297 24.1155 36.2913 23.9855 36.0313C23.8771 35.7713 23.8338 35.4897 23.8338 35.208C23.8338 34.6447 24.0505 34.0813 24.4621 33.6697C24.6788 33.4747 24.8955 33.323 25.1771 33.2147C25.9788 32.868 26.9321 33.063 27.5388 33.6697C27.7338 33.8863 27.8855 34.103 27.9938 34.3847C28.1021 34.6447 28.1671 34.9263 28.1671 35.208C28.1671 35.4897 28.1021 35.7713 27.9938 36.0313Z" fill="#A30711"/>
        </svg>
        <h1>Cuidado!</h1>
        <h2>Deseja mesmo deletar esse usuário?</h2>
        <section>
          <button @click="isConfirmDeleteModal = false; deleteUser([userToDeleteId]);">Sim</button>
          <input autocomplete='off' @click="isConfirmDeleteModal = false;" type="button" value="Cancelar">
        </section>
      </div>
    </MyModal>
    <MyModal :width="'330px !important'" :height="'299px !important'" :isOpen="isConfirmStatusModalOpen" :class-name="'confirm-delete'" :toggleModal="(v: boolean) => isConfirmStatusModalOpen = v" :zIndex="100">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
          <path d="M42.2722 12.6747L29.4021 5.24301C27.3005 4.02967 24.7005 4.02967 22.5771 5.24301L9.72881 12.6747C7.62715 13.888 6.32715 16.1413 6.32715 18.5897V33.4097C6.32715 35.8363 7.62715 38.0897 9.72881 39.3247L22.5988 46.7563C24.7005 47.9697 27.3005 47.9697 29.4238 46.7563L42.2938 39.3247C44.3955 38.1113 45.6955 35.858 45.6955 33.4097V18.5897C45.6738 16.1413 44.3738 13.9097 42.2722 12.6747ZM24.3755 16.7913C24.3755 15.903 25.1121 15.1663 26.0005 15.1663C26.8888 15.1663 27.6255 15.903 27.6255 16.7913V28.1663C27.6255 29.0547 26.8888 29.7913 26.0005 29.7913C25.1121 29.7913 24.3755 29.0547 24.3755 28.1663V16.7913ZM27.9938 36.0313C27.8855 36.2913 27.7338 36.5297 27.5388 36.7463C27.1271 37.158 26.5855 37.3747 26.0005 37.3747C25.7188 37.3747 25.4371 37.3097 25.1771 37.2013C24.8955 37.093 24.6788 36.9413 24.4621 36.7463C24.2671 36.5297 24.1155 36.2913 23.9855 36.0313C23.8771 35.7713 23.8338 35.4897 23.8338 35.208C23.8338 34.6447 24.0505 34.0813 24.4621 33.6697C24.6788 33.4747 24.8955 33.323 25.1771 33.2147C25.9788 32.868 26.9321 33.063 27.5388 33.6697C27.7338 33.8863 27.8855 34.103 27.9938 34.3847C28.1021 34.6447 28.1671 34.9263 28.1671 35.208C28.1671 35.4897 28.1021 35.7713 27.9938 36.0313Z" fill="#A30711"/>
        </svg>
        <h1>Cuidado!</h1>
        <h2>Você está tentando desabilitar uma conta de usuário. Se fizer isso o usuário não poderá mais gerenciar o sistema.</h2>
        <section>
          <button @click="isConfirmStatusModalOpen = false; userToToggleStatus?.id ? updateUserStatus([userToToggleStatus.id], '0') : updateUserStatus(selectedUsers.map((user) => user.id!), '0')" style="width: 93px">Desabilitar</button>
          <input autocomplete='off' @click="isConfirmStatusModalOpen = false;" type="button" value="Cancelar">
        </section>
      </div>
    </MyModal>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useAxios } from '@/api/axios'
import { UserModel } from '@/models/UserModel'
import { AxiosResponse } from 'axios'
import SpinnerLoader from '@/components/loader/SpinnerLoader.vue'
import MyModal from '@/components/modal/MyModal.vue'
import { user as authenticatedUser } from '@/global/states/GlobalState'

const axios = useAxios()
const users = ref<UserModel[]>()
const selectedUsers = ref<UserModel[]>([])
const isMobileOptionsFilterOpen = ref<boolean>(false)
const filter = ref<Record<string, string>>({})
const userToToggleStatus = ref<{ id: number, status: boolean }>()
const isConfirmStatusModalOpen = ref<boolean>(false)
const isConfirmDeleteModal = ref<boolean>(false)
const userToDeleteId = ref<number>(0)
const canManageUsers = computed(() => authenticatedUser.value.can!('create_users') || authenticatedUser.value.can!('update_users') || authenticatedUser.value.can!('delete_users'))
const allowedUsers = computed(() => {
  return users.value?.filter((u) => ((u.organization_id == authenticatedUser.value.organization_id && u.roles[0].name != 'Super Admin') || authenticatedUser.value.hasRoles!(['Super Admin'])))
})

const toggleOverflow = () => {
  isMobileOptionsFilterOpen.value = !isMobileOptionsFilterOpen.value
  document.body.style.overflow = isMobileOptionsFilterOpen.value ? 'hidden' : 'auto'
}

onMounted(() => {
  getUsers()
})

function handleUserToggleStatus (e: Event, id: number) {
  const isChecked = (e.target as HTMLInputElement).checked

  if (isChecked) {
    updateUserStatus([id], '1')
  } else {
    userToToggleStatus.value = { id: id, status: isChecked }
    isConfirmStatusModalOpen.value = true
  }
}

function getUsers () {
  users.value = undefined
  axios.get('/api/users', { params: filter.value })
    .then((res: AxiosResponse<UserModel[]>) => {
      users.value = res.data.map((user) => ({ ...user, status: user.status == 'active' }))
    })
}

function updateUserStatus (ids: number[], status: '1' | '0') {
  const formData = new FormData()
  ids.forEach((id, index) => {
    const userIndex = users.value?.findIndex((u) => u.id == id)
    if (userIndex) {
        users.value![userIndex].status = !!parseInt(status)
        formData.append(`user_ids[${index}]`, String(id))
    }
  })
  formData.append('status', status)

  const usersValue = users.value
  // users.value = undefined
  selectedUsers.value = []

  axios.patch('/api/users', formData)
    .then((res) => {
      users.value = usersValue
    })
}

function deleteUser (ids: number[]) {
  users.value = users.value?.filter(user => !ids.includes(user.id as number))
  const usersValue = users.value
  users.value = undefined

  axios.delete('/api/users', { user_ids: ids })
    .then(() => {
      users.value = usersValue
      selectedUsers.value = []
    })
}
</script>

<style lang="scss">
  .manage-users{
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 32px 0 0 0;
    .back{
      display: flex;
      align-items: center;
      column-gap: 16px;
      cursor: pointer;
      margin: 0 auto 45px 0;
      animation: enterContent ease .4s;
      &:hover{
        figure{
          background: $fluxoo-primary-color;
        }
        svg{
          stroke: #FFF;
        }
        p{
          color: $fluxoo-primary-color;
        }
      }
      figure{
        width: 40px;
        min-width: 40px;
        height: 40px;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        border-radius: 50px;
        background: #F5F5F5;
        transition: background .2s;
        svg{
        width: 24px;
        min-width: 24px;
        height: 24px;
        min-height: 24px;
        stroke: #090909;
        transition: stroke .2s;
        }
      }
      p{
          color: #090909;
          font-family: 'Nunito', sans-serif;
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin: 0;
          text-align: start;
          transition: color .2s
      }
    }
    .header{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 20px;
      margin: 0 0 48px 0;
      .search{
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        input{
            width: 100%;
            height: 55px;
            border: none;
            border-radius: 16px;
            background: #F5F5F5;
            color: $fluxoo-primary-color;
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding: 20px 56px 20px 20px;
            box-sizing: border-box;
            transition: background .2s;
            &:focus{
                outline: none;
                &::placeholder{
                  color: transparent
                }
                & ~ svg{
                  stroke: $fluxoo-primary-color;
                }
            }
            &::placeholder{
                color: #090909;
                font-family: 'Inter', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            &:read-only{
                cursor: default !important;
            }
        }
        svg{
            width: 20px;
            min-width: 20px;
            height: 20px;
            min-height: 20px;
            position: absolute;
            right: 20px;
            stroke: #090909;
            cursor: pointer;
        }
      }
      .filters{
        display: flex;
        align-items: center;
        column-gap: 15px;
        button{
          width: 50px;
          height: 50px;
          border-radius: 12px;
          border: 1px solid #D5D5D5;
          transition: border-color .2s;
          cursor: pointer;
          &:hover{
            border-color: $fluxoo-primary-color;
            outline: 1px solid $fluxoo-primary-color;
            svg{
              stroke: $fluxoo-primary-color;
            }
          }
          figure{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            svg{
              width: 24px;
              min-width: 24px;
              height: 24px;
              min-height: 24px;
              stroke: #090909;
              transition: stroke .2s;
            }
          }
          &:nth-child(1)::before{
            content: 'Novo usuário';
            width: 106px;
            right: -5px;
          }
          &:nth-child(2)::before{
            content: 'Filtros gerais';
            width: 105px;
            right: -2px;
          }
        }
      }
    }
    .users{
      box-sizing: border-box;
      width: 100%;
      margin: 0 0 220px 0;
      &-title{
        width: 100%;
        margin: 0 0 15px 0;
      }
      &-list{
        width: 100%;
        height: 120px;
        border-radius: 28px;
        border: 1px solid #D3D3D3;
        background: #FFF;
        margin: 0 0 10px 0;
        animation: enterContent ease-in-out .6s;
      }
      &-full{
        display: flex;
        flex-direction: column;
        align-items: start;
        border-radius: 24px;
        border: 2px solid $fluxoo-primary-color;
        background: #F5F8F7;
        padding: 20px 24px;
        margin: 0 0 24px 0;
        position: relative;
        animation: enterContent ease-in-out .6s;
        h1{
          color: #292F31;
          font-family: 'Inter', sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin: 0 0 4px 0;
          text-align: start;
        }
        h2{
          color: #949494;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0 0 24px 0;
          text-align: start;
          strong{
            color: #292F31;
          }
        }
        .close{
          width: 24px;
          min-width: 24px;
          height: 24px;
          min-height: 24px;
          stroke: $fluxoo-primary-color;
          transition: stroke .2s;
          position: absolute;
          top: 20px;
          right: 24px;
          cursor: pointer;
          &:hover{
            stroke: $fluxoo-primary-color-hover;
          }
        }
        .actions{
          width: 100%;
          max-width: 750px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          div{
            display: flex;
            align-items: center;
            column-gap: 6px;
            cursor: pointer;
            &:hover{
              p{
                color: $fluxoo-primary-color;
              }
              svg{
                stroke: $fluxoo-primary-color;
                fill: $fluxoo-primary-color;
              }
            }
            p{
              color: #090909;
              font-family: 'Inter', sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin: 0;
              transition: color .2s;
            }
            svg{
              width: 24px;
              min-width: 24px;
              height: 24px;
              min-width: 24px;
              stroke: #292D32;
              fill: #292D32;
              transition: stroke .2s, fill .2s;
            }
            &:nth-child(3){
              svg{
                width: 12px;
                min-width: 12px;
                height: 10px;
                min-height: 12px;
              }
            }
            &:nth-child(4){
              svg{
                width: 20px;
                min-width: 20px;
                height: 20px;
                min-height: 20px;
              }
            }
          }
        }
      }
      .doc-grid{
        display: grid !important;
        grid-template-columns: 24px 20% 1fr 150px 68px;
        column-gap: 15px;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px;
        box-sizing: border-box;
        label{
          display: flex;
          flex-direction: column;
          align-items: start;
          h1{
            color: #090909;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: start;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          h2{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #6F6F6F;
            font-family: 'Inter', sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: start;
            margin: 6px 0 0 0;
            svg{
              fill: #090909;
              transition: fill .2s;
              cursor: pointer;
              &:hover{
                fill: $fluxoo-primary-color;
              }
            }
          }
          &.stats{
            width: 105px;
            display: flex;
            column-gap: 10px;
            cursor: pointer;
            input{
              display: none;
              &:checked{
                & ~ span{
                  background: $fluxoo-primary-color-hover;
                  &::before{
                    background: $fluxoo-primary-color;
                    left: calc(100% - 25px);
                  }
                  &::after{
                    content: 'Ativo';
                    color: $fluxoo-primary-color;
                  }
                }
              }
            }
            span{
              width: 43px;
              height: 11px;
              background: #949494;
              border-radius: 12px;
              position: relative;
              transition: background .2s;
              &::before{
                content: '';
                width: 25px;
                height: 25px;
                background: #D5D5D5;
                border-radius: 50%;
                position: absolute;
                top: -7px;
                left: 0;
                transition: background .2s, left .2s;
              }
              &::after{
                content: 'Inativo';
                position: absolute;
                left: calc(100% + 10px);
                bottom: -4px;
                color: #949494;
                font-family: 'Inter', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                transition: color .2s;
              }
            }
          }
          &.title{
            h1{
              -webkit-line-clamp: 3;
            }
          }
          &.check{
            input{
              display: none;
              &:checked ~ span{
                background: $fluxoo-primary-color;
                border-color: $fluxoo-primary-color;
              }
            }
            span{
              display: flex;
              align-items: center;
              justify-content: center;
              width: 24px;
              min-width: 24px;
              height: 24px;
              min-height: 24px;
              border-radius: 8px;
              border: 1px solid #6F6F6F;
              transition: border-color .2s, background .2s;
              cursor: pointer;
            }
          }
          &.date{
            h1{
              color: #090909;
              font-family: 'Inter', sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
            .tag{
              margin: 4px 0 0 0;
            }
            &.late{
              h1{
                color: #EB1320;
              }
              .tag{
                display: none;
              }
            }
          }
          &.actions{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            row-gap: 20px;
            button{
              width: 24px;
              min-width: 24px;
              height: 24px;
              min-height: 24px;
              cursor: pointer;
              figure{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
              }
              &:hover{
                svg{
                  stroke: $fluxoo-primary-color;
                }
              }
              &:nth-child(1)::before{
                content: 'Editar';
                width: 60px;
              }
              &:nth-child(2)::before{
                content: 'Deletar';
                width: 68px;
              }
            }
            svg{
              width: 24px;
              min-width: 24px;
              height: 24px;
              min-height: 24px;
              stroke: #090909;
              fill: #090909;
              transition: stroke .2s, fill .2s;
              cursor: pointer;
              &:hover{
                fill: $fluxoo-primary-color;
              }
            }
          }
        }
      }
    }
    .action-filters{
      display: none;
    }
    .modal{
      &__content{
        margin: 15px 0 auto 0;
      }
      &.confirm-delete{
        div{
          div{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 26px 15px 24px;
            h1{
              color: #090909;
              font-family: 'Nunito', sans-serif;
              font-size: 28px;
              font-style: normal;
              font-weight: 900;
              line-height: normal;
              margin: 2px 0 7px 0;
            }
            h2{
              color: #6F6F6F;
              text-align: center;
              font-family: 'Inter', sans-serif;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin: 0;
            }
            section{
              width: 100%;
              display: flex;
              align-items: center;
              flex-direction: row;
              justify-content: space-between;
              padding: 0;
              margin: 33px 0 0 0;
              button{
                height: 30px;
                width: 80px;
                top: unset;
                color: #090909;
                font-family: 'Inter', sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: color .2s;
                cursor: pointer;
                &:hover{
                  color: $fluxoo-primary-color;
                }
              }
              input{
                height: 60px;
                border-radius: 30px;
                background: #090909;
                color: #FFF;
                font-family: 'Inter', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                border: none;
                width: 173px;
                cursor: pointer;
              }
            }
          }
        }
        button{
          display: flex;
          top: 28px;
          right: 28px;
        }
      }
    }
    input[type="text"]{
      &:focus{
        border: none !important;
      }
    }
  }

  @media(max-width: 950px){
    .manage-users{
      .users{
        .doc-grid{
          padding: 0 10px;
          column-gap: 15px;
          grid-template-columns: 24px 20% 1fr 150px 68px;
        }
      }
    }
  }

  @media(max-width: 850px){
    .manage-users{
      .users{
        margin: 0 0 115px 0;
        .users-title{
          label{
            display: none;
            &:first-child{
              display: flex;
              input:checked ~ span::after{
                color: $fluxoo-primary-color;
              }
              span{
                position: relative;
                &::after{
                  content: 'Titulo e Origem';
                  color: #090909;
                  font-family: 'Inter', sans-serif;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 25px;
                  white-space: nowrap;
                  position: absolute;
                  left: calc(100% + 10px);
                  transition: color .2s;
                }
              }
            }
          }
        }
        .users-list{
          padding: 24px 24px 24px 24px;
          height: unset;
          min-height: 138px;
          position: relative;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-auto-rows: 24px 50px;
          grid-template-areas:
          "check check actions stats"
          "name name email email";
          row-gap: 15px;
          justify-content: space-between;
          animation: enterContentGrid ease-in-out .6s;
          label{
            h2{
              margin: 0;
            }
            &:nth-child(1){
              grid-area: check;
            }
            &:nth-child(2){
              grid-area: name;
              justify-content: center;
              h1{
                -webkit-line-clamp: 2;
              }
            }
            &:nth-child(3){
              grid-area: email;
              justify-content: center;
              align-items: end;
              h1{
                overflow: unset;
                text-overflow: unset;
                display: unset;
                -webkit-line-clamp: unset;
                -webkit-box-orient: unset;
                text-align: end;
              }
              h2{
                width: unset;
              }
            }
            &:nth-child(4){
              margin: 0 0 0 auto;
              justify-content: center;
              position: absolute;
              right: 100px;
              top: 31px;
            }
            &:nth-child(5){
              grid-area: stats;
              max-width: 70px;
              margin: 0 0 0 auto;
              min-width: 70px;
            }
            .exception{
              display: flex;
              position: absolute;
              top: 31px;
              left: 57px;
              margin: 0;
            }
          }
        }
        .users-full{
          .actions{
            flex-wrap: wrap;
            align-items: start;
            justify-content: start;
            gap: 20px;
          }
        }
      }
    }
  }

  @media(max-width: 768px){
    .manage-users{
      .header{
        .filters{
          display: none;
        }
      }
      .action-filters{
        display: flex;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: end;
        row-gap: 8px;
        position: fixed;
        bottom: 15px;
        right: 15px;
        z-index: 4;
        box-sizing: border-box;
        transition: opacity 0.3s;
        &.active{
          width: 100vw;
          height: 100dvh;
          display: flex;
          flex-direction: column;
          justify-content: end;
          align-items: end;
          row-gap: 8px;
          position: fixed;
          background: #292F3150;
          top: 0;
          bottom: unset;
          right: unset;
          left: 0;
          z-index: 4;
          padding: 15px;
          box-sizing: border-box;
          button{
            display: flex;
            align-items: center;
            justify-content: center;
            animation: enterContent ease .4s;
            &:last-child{
              figure{
                svg{
                  transform: rotate(0);
                }
              }
            }
          }
        }
        button{
          display: none;
          width: 45px;
          min-width: 45px;
          height: 45px;
          min-height: 45px;
          background: #FFF;
          border-radius: 50%;
          transition: background .2s;
          filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.15));
          cursor: pointer;
          &:hover{
            background: $fluxoo-primary-color;
            figure{
              svg{
                stroke: #FFF;
              }
            }
          }
          figure{
            widows: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            svg{
              width: 20px;
              min-width: 20px;
              height: 20px;
              min-height: 20px;
              stroke: #090909;
              transition: stroke .2s;
            }
          }
          &:last-child{
            display: flex;
            align-items: center;
            justify-content: center;
            background: $fluxoo-primary-color;
            figure{
              svg{
                stroke: #FFF;
                margin: 0 0 0 -1px;
                transform: rotate(-405deg);
                transition: transform .2s, background .2s;
              }
            }
            &:hover{
              background: $fluxoo-primary-color-hover;
            }
          }
        }
      }
    }
  }

  @media(max-width: 680px){
    .manage-users{
      .modal{
        &.date-filter{
          padding: 15px;
          .modal__content{
            max-width: 400px !important;
            .dates{
              flex-direction: column;
              row-gap: 30px;
              section{
                max-width: unset;
                .date-picker{
                  div{
                    justify-content: space-between;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media(max-width: 425px){
    .manage-users{
      .action-filters{
        bottom: 15px;
        &.active{
          padding-bottom: 15px;
        }
      }
      .modal {
        padding: 0 15px;
        &__content{
          border-radius: 28px;
          max-width: unset !important;
          max-height: unset !important;
          bottom: unset;
          left: unset;
          right: unset;
          top: 0;
          opacity: 0;
          transition: all 0.2s ease-out;
          &.closed {
              opacity: 0;
              transform: translateY(-110%) !important;
          }
          &.open {
              opacity: 1;
              transform: translateY(0) !important;
          }
        }
      }
    }
  }
</style>
