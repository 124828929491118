<template>
  <div id="alerts">
    <label @click="$router.push('/admin/home')" class="back">
        <figure>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.57 5.93018L3.5 12.0002L9.57 18.0702M20.5 12.0002H3.67" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </figure>
        <p>Alertas</p>
    </label>
    <div class="alerts" v-if="alerts && alerts.length">
      <div class="alert" v-for="alert in alerts">
        <h1>{{ alert.title }}</h1>
        <p>{{ alert.description }}</p>
      </div>
    </div>
    <div class="no-options full" v-else-if="alerts && !alerts.length">
      <svg xmlns="http://www.w3.org/2000/svg" width="173" height="173" viewBox="0 0 173 173" fill="none">
        <path opacity="0.4" d="M151.952 61.8477V111.152C151.952 119.226 147.627 126.723 140.635 130.832L97.8176 155.556C90.8256 159.593 82.1754 159.593 75.1112 155.556L32.2937 130.832C25.3016 126.795 20.9766 119.298 20.9766 111.152V61.8477C20.9766 53.7744 25.3016 46.2774 32.2937 42.1687L75.1112 17.4441C82.1033 13.4075 90.7535 13.4075 97.8176 17.4441L140.635 42.1687C147.627 46.2774 151.952 53.7023 151.952 61.8477Z" fill="#292D32"/>
        <path d="M86.5 99.1146C83.5446 99.1146 81.0938 96.6638 81.0938 93.7084V55.8646C81.0938 52.9092 83.5446 50.4584 86.5 50.4584C89.4554 50.4584 91.9062 52.9092 91.9062 55.8646V93.7084C91.9062 96.6638 89.4554 99.1146 86.5 99.1146Z" fill="#292D32"/>
        <path d="M86.4993 124.344C85.5623 124.344 84.6251 124.127 83.7601 123.767C82.8231 123.406 82.1021 122.902 81.3813 122.253C80.7325 121.533 80.2282 120.74 79.7957 119.875C79.4352 119.01 79.291 118.072 79.291 117.135C79.291 115.261 80.0117 113.387 81.3813 112.017C82.1021 111.369 82.8231 110.864 83.7601 110.504C86.4272 109.35 89.5991 109.999 91.6174 112.017C92.2662 112.738 92.7705 113.459 93.1309 114.396C93.4913 115.261 93.7077 116.198 93.7077 117.135C93.7077 118.072 93.4913 119.01 93.1309 119.875C92.7705 120.74 92.2662 121.533 91.6174 122.253C90.2478 123.623 88.4456 124.344 86.4993 124.344Z" fill="#292D32"/>
      </svg>
      <h1>Não há alertas, por enquanto.</h1>
    </div>
    <SpinnerLoader v-else />
  </div>
</template>

<script setup lang="ts">
import { useAxios } from '@/api/axios'
import SpinnerLoader from '@/components/loader/SpinnerLoader.vue'
import AlertModel from '@/models/AlertModel'
import { AxiosResponse } from 'axios'
import { onMounted, ref } from 'vue'

const axios = useAxios()
const alerts = ref<AlertModel[]>()

function getAlerts () {
  axios.get('/api/alerts').then((res: AxiosResponse<AlertModel[]>) => {
    alerts.value = res.data
  })
}

onMounted(() => {
  getAlerts()
})
</script>

<style lang="scss">

  #alerts{
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0;
    margin: 0 0 50px 0;
    animation: enterContent ease .4s;
    .back{
        display: flex;
        align-items: center;
        column-gap: 16px;
        cursor: pointer;
        margin: 0 auto 45px 0;
        &:hover{
            figure{
              background: $fluxoo-primary-color;
            }
            svg{
              stroke: #FFF;
            }
            p{
              color: $fluxoo-primary-color;
            }
        }
        figure{
            width: 40px;
            min-width: 40px;
            height: 40px;
            min-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            border-radius: 50px;
            background: #F5F5F5;
            transition: background .2s;
            svg{
            width: 24px;
            min-width: 24px;
            height: 24px;
            min-height: 24px;
            stroke: #090909;
            transition: stroke .2s;
            }
        }
        p{
            color: #090909;
            font-family: 'Nunito', sans-serif;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0;
            transition: color .2s
        }
    }
    .alerts{
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      .alert{
        display: flex;
        flex-direction: column;
        border-radius: 24px;
        background: #F1F1F1;
        padding: 17px 20px;
        text-align: start;
        row-gap: 10px;
        h1{
          color: #080808;
          font-family: 'Nunito', sans-serif;
          font-size: 22px;
          font-style: normal;
          font-weight: 800;
          line-height: 22px;
          margin: 0;
        }
        p{
          color: #080808;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin: 0;
        }
      }
    }
  }
</style>
